import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class ClientApi {
  static listClients(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('client', body, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        return res.data;
      });
  }

  // static listClientFamilyCollectionForSelect(
  //   token: string | undefined,
  // ): Promise<any> {
  //   return axiosInstance
  //     .get('client/collectionForSelect', {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       return res.data;
  //     });
  // }

  static listClientFamilyCollection(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/collection', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listArchivedClientFamilyCollection(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/collection/archive', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listArchivedClients(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/archive', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createClient(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('client/create', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getCategoriesAndRisks(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/form/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClientData(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/data/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateClientData(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/data/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClientFamily(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/family/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static updateClientRelation(
    token: string | undefined,
    clientId: string | undefined,
    relationId: string,
    type: RelationshipType,
  ): Promise<any> {
    return axiosInstance
      .post(
        'client/relation/' + clientId,
        {
          relationId,
          type,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateClientArchiveState(
    token: string | undefined,
    clientId: string | undefined,
    archived: boolean,
  ): Promise<any> {
    return axiosInstance
      .post(
        'client/archive/' + clientId,
        {
          archived,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateClientRole(
    token: string | undefined,
    clientId: string | undefined,
    role: ReferencePersonRole,
  ): Promise<any> {
    return axiosInstance
      .post(
        'client/role/' + clientId,
        {
          role,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static addSupervisor(
    token: string | undefined,
    clientId: string | undefined,
    supervisorId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .post(
        `client/supervisor/${clientId}/${supervisorId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static removeSupervisor(
    token: string | undefined,
    clientId: string | undefined,
    supervisorId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .delete(`client/supervisor/${clientId}/${supervisorId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpFormOrderer(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('client/help-form/orderer', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClientHelpForms(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/help-form/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getMinimalClientHelpForms(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/help-form/minimal/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static addClientHelpForm(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/help-form/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveClientHelpFormsData(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/help-form/data/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveClientHelpFormData(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/help-form/single-data/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClientHelpFormSupervisor(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/help-form/supervisor/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getClientSuffering(
    token: string | undefined,
    clientId: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('client/suffering/' + clientId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static saveClientSuffering(
    token: string | undefined,
    clientId: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/suffering/' + clientId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getOrdererInvoiceProtocols(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('client/help-form/orderer/iProtocols', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getAllSocialWorkers(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('client/help-form/socialWorkers', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
