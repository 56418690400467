import { FC, useContext, useEffect, useState } from 'react';
import { ClientTable } from '../../../components/client';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { MenuWrapper } from '../../../components/core';
import { StorageType } from '@casecare/types';
import { StorageTable } from '../../../components/storage';
import { Breadcrumbs, CircularProgress, Link, Typography } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';
import { useQuery } from 'react-query';
import { StorageApi } from '../../../api';

interface StorageContainerProps {
  storageItemId: string;
  storageItemIndexId?: string;
}

const StorageContainer: FC<StorageContainerProps> = (props) => {
  const [currentStorageItemId, setCurrentStorageItemId] = useState<
    string | null
  >(null);
  const [breadCrumb, setBreadCrumb] = useState<any[]>([]);
  const context = useContext(AppContext);

  useEffect(() => {
    setCurrentStorageItemId(props.storageItemId);
  }, [props.storageItemId]);

  const breadCrumbQuery = useQuery({
    queryKey: ['storageItemBreadCrumb', currentStorageItemId],
    queryFn: () => {
      return StorageApi.breadCrumb(
        context.authToken,
        currentStorageItemId ?? props.storageItemId,
        props.storageItemIndexId,
      );
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!breadCrumbQuery.isLoading) {
      if (breadCrumbQuery.error) {
        console.error(breadCrumbQuery.error);
      } else if (breadCrumbQuery.data && breadCrumbQuery.data.data) {
        const breadCrumbs: BreadCrumbSegment[] = [];
        for (const breadCrumb of breadCrumbQuery.data.data) {
          breadCrumbs.push({
            // path: `../${breadCrumb.id}`,
            path: breadCrumb.id,
            name: breadCrumb.name,
          });
        }
        setBreadCrumb(breadCrumbs);
      }
    }
  }, [breadCrumbQuery.data, breadCrumbQuery.error, breadCrumbQuery.isLoading]);

  return (
    <>
      <Breadcrumbs
        maxItems={3}
        itemsBeforeCollapse={0}
        itemsAfterCollapse={3}
        separator="›"
        aria-label="breadcrumb-storage"
        sx={{
          pl: 5,
          pr: 5,
          pt: 2,
          pb: 2,
        }}
      >
        {breadCrumb.map((segment, key, self) => {
          if (segment && segment.name) {
            return segment === self[self.length - 1] || !segment.path ? (
              <Typography key={key} color="text.primary">
                {segment.name}
              </Typography>
            ) : (
              // <Link
              //   component={RouterLink}
              //   underline="hover"
              //   key={key}
              //   color="inherit"
              //   to={segment.path}
              // >
              //   {segment.name}
              // </Link>
              <Link
                underline="hover"
                key={key}
                color="inherit"
                onClick={() => setCurrentStorageItemId(segment.path)}
              >
                {segment.name}
              </Link>
            );
          } else return <></>;
        })}
      </Breadcrumbs>
      {currentStorageItemId ? (
        <StorageTable
          storageItemId={currentStorageItemId}
          openFolder={(params: any) => {
            setCurrentStorageItemId(params.row.id);
          }}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default StorageContainer;
