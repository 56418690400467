import { FC, useContext, useEffect, useState } from 'react';
import {
  ClientSubTabType,
  ClientTabType,
  FamilyTabType,
  Protocol,
} from '../../../../types';
import { AppContext } from '../../../../hooks/context';
import {
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Typography,
  Link,
  Button,
  Box,
  FormGroup,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { ProtocolApi } from '../../../../api';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { useQuery } from 'react-query';
import { FamilySubTabType } from '../../../../types/family-sub-tab-type';
import { WysiwygEditor } from '../../../core/wysiwyg-editor/wysiwyg-editor';
import { useSavePrompt } from '../../../../hooks/useBlocker';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro';
import { isEqual } from 'underscore';

interface ProtocolFieldsProps {
  protocol?: Protocol;
  familyId?: string;
  clientId?: string;
  save?: boolean;
  onChange?: (changes: boolean) => void;
  onSave?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  open: boolean;
  onDisableSave?: (disabled: boolean) => void;
  isEdit?: boolean;
  submitOnSave?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  timeTrackingId?: string;
}

interface SelectableHelpForm {
  id: string;
  name: string;
  helpFormId: string;
  user?: {
    id: string;
    name: string;
  };
  users?: {
    id: string;
    name: string;
  }[];
  hoursUsed?: number;
  hoursMax?: number | string;
}

const ProtocolFields: FC<ProtocolFieldsProps> = (props) => {
  const context = useContext(AppContext);

  const [startDate, setStartDate] = useState(moment().subtract(1, 'hour'));
  const [endDate, setEndDate] = useState(moment());
  const [hours, setHours] = useState<any>(1);
  const [billable, setbillable] = useState(true);
  const [place, setPlace] = useState('');
  const [personsPresent, setPersonsPresent] = useState('');
  const [contentOfCare, setContentOfCare] = useState('');
  const [helpFormId, setHelpFormId] = useState<string | undefined>(undefined);
  const [selectedHelpForm, setSelectedHelpForm] =
    useState<SelectableHelpForm | null>(null);

  const [hoursHelptext, setHoursHelptext] = useState('');

  const [applyToAllClients, setApplyToAllClients] = useState(false);

  const [availableHelpforms, setAvailableHelpForms] = useState<
    SelectableHelpForm[]
  >([]);

  const [saving, setSaving] = useState(false);

  const [selectedSupervisors, setSelectedSupervisors] = useState<
    { id: string; name: string }[]
  >([]);

  const [allowSupervisorChange, setAllowSupervisorChange] = useState(true);

  const hasChanges = () => {
    if (saving) return false;
    if (props.protocol) {
      if (
        parseInt(props.protocol.hours) !== parseInt(hours) ||
        props.protocol.place !== place ||
        props.protocol.personsPresent !== personsPresent ||
        props.protocol.contentOfCare !== contentOfCare ||
        props.protocol.billable !== billable
      ) {
        return true;
      }
    } else {
      if (place || personsPresent || contentOfCare || helpFormId) {
        return true;
      }
    }
    return false;
  };

  useSavePrompt(
    () => onSave(),
    () => console.log(''),
    hasChanges(),
  );

  useQuery({
    queryKey: ['getHelpForms'],
    queryFn: () => {
      return props.clientId
        ? ProtocolApi.getHelpForms(context.authToken, props.clientId)
        : ProtocolApi.getFamilyHelpForms(context.authToken, props.familyId);
    },
    onSuccess: (data) => {
      setAvailableHelpForms(data.data.data);
    },
    enabled: context.authToken !== undefined && props.open,
  });

  useEffect(() => {
    if (availableHelpforms && availableHelpforms.length > 0) {
      if (!props.isEdit && !props.protocol) {
        if (availableHelpforms.length === 1) {
          setSelectedHelpForm(availableHelpforms[0]);
          if (availableHelpforms[0].user) {
            setSelectedSupervisors([availableHelpforms[0].user]);
          }
        } else {
          const isIn: any[] = [];
          let user;
          availableHelpforms.forEach((hf) => {
            if (
              hf.users &&
              hf.users.filter((u) => u.id === context.userId).length > 0
            ) {
              user = hf.users.filter((u) => u.id === context.userId)[0];
              isIn.push(hf);
            }
          });
          if (isIn.length === 1 && user) {
            setSelectedHelpForm(isIn[0]);
            setSelectedSupervisors([user]);
          }
        }
      } else {
        if (!props.protocol || !props.protocol.supervisorIds) {
          setAllowSupervisorChange(false);
          return;
        }
        const chf = availableHelpforms.find(
          (p) => p.id === props.protocol?.clientHelpFormId,
        );
        if (chf && chf.users) {
          let notFound = false;
          const supervisors = [];
          for (const supervisorId of props.protocol.supervisorIds) {
            const user = chf.users.find((u) => u.id === supervisorId);
            if (!user) {
              notFound = true;
            } else {
              supervisors.push(user);
            }
          }
          if (notFound) {
            setAllowSupervisorChange(false);
          } else {
            setSelectedSupervisors(supervisors);
            setSelectedHelpForm(chf);
          }
        } else {
          setAllowSupervisorChange(false);
        }
      }
    }
  }, [availableHelpforms, props.isEdit, props.protocol]);

  useEffect(() => {
    if (selectedHelpForm && selectedSupervisors.length === 0) {
      let user:
        | {
            id: string;
            name: string;
          }
        | undefined = undefined;
      selectedHelpForm.users?.forEach((u) => {
        if (u.id === context.userId) {
          user = u;
        }
      });
      if (user) setSelectedSupervisors([user]);
    }
  }, [selectedHelpForm, selectedSupervisors]);

  useEffect(() => {
    if (
      context.isAdmin &&
      selectedSupervisors.length === 0 &&
      selectedHelpForm &&
      selectedHelpForm.user
    ) {
      setSelectedSupervisors([selectedHelpForm.user]);
    }
  }, [selectedHelpForm, selectedSupervisors, context.isAdmin]);

  useEffect(() => {
    if (props.protocol) {
      setStartDate(moment.utc(props.protocol.startDate).local());
      setEndDate(moment.utc(props.protocol.endDate).local());
      setHours(props.protocol.hours);
      setPlace(props.protocol.place);
      setPersonsPresent(props.protocol.personsPresent);
      setContentOfCare(props.protocol.contentOfCare);
      setbillable(props.protocol.billable);
      setHelpFormId(
        props.protocol.helpFormId || (props.protocol as any).helpForm?.id,
      );
      setSaving(false);
    } else {
      const start = props.startDate
        ? props.startDate
        : moment().subtract(1, 'hour').startOf('minute');
      const remainderStart = 15 - (start.minute() % 15);
      start.add(remainderStart, 'minutes');

      const end = props.endDate ? props.endDate : moment().startOf('minute');
      const remainderEnd = 15 - (end.minute() % 15);
      end.add(remainderEnd, 'minutes');

      setHours(1);
      setStartDate(start);
      setEndDate(end);
      setPlace('');
      setPersonsPresent('');
      setContentOfCare('');
      setbillable(true);
      setApplyToAllClients(false);
      setSaving(false);
      setAllowSupervisorChange(true);
    }
  }, [props.protocol]);

  useEffect(() => {
    const protocol = props.protocol;
    if (props.onChange && protocol) {
      props.onChange(
        !moment(protocol.startDate).isSame(startDate) ||
          !moment(protocol.endDate).isSame(endDate) ||
          protocol.hours !== hours ||
          protocol.place !== place ||
          protocol.personsPresent !== personsPresent ||
          protocol.contentOfCare !== contentOfCare ||
          protocol.billable !== billable ||
          protocol.helpFormId !== helpFormId,
      );
    }
  }, [
    startDate,
    endDate,
    hours,
    place,
    personsPresent,
    contentOfCare,
    billable,
    helpFormId,
  ]);

  useEffect(() => {
    if (props.save || props.submitOnSave) {
      onSave();
    }
  }, [props.save, props.submitOnSave]);

  useEffect(() => {
    if (props.onDisableSave && !props.protocol) {
      if (props.protocol) {
        props.onDisableSave(false);
      } else if (
        !selectedHelpForm ||
        selectedSupervisors.length === 0 ||
        (applyToAllClients &&
          (selectedSupervisors ? selectedSupervisors.length > 1 : false))
      ) {
        props.onDisableSave(true);
      } else {
        props.onDisableSave(false);
      }
    }
  }, [
    selectedHelpForm,
    selectedSupervisors,
    applyToAllClients,
    props.onDisableSave,
  ]);

  useEffect(() => {
    let diff = endDate.diff(startDate, 'hours', true);
    if (selectedSupervisors && selectedSupervisors.length > 1) {
      diff = diff * selectedSupervisors.length;
      setHoursHelptext(context.i18n.hoursMultiplied);
    } else {
      setHoursHelptext('');
    }
    if (props.isEdit) {
      if (props.protocol) {
        if (
          props.protocol.supervisorIds &&
          props.protocol.supervisorIds.length > 1
        ) {
          if (
            !moment(props.protocol.startDate, 'DD.MM.YYYY | HH:mm').isSame(
              startDate,
            ) ||
            !moment(props.protocol.endDate, 'DD.MM.YYYY | HH:mm').isSame(
              endDate,
            )
          ) {
            diff = diff * props.protocol.supervisorIds.length;
            setHoursHelptext(context.i18n.hoursMultiplied);
          }
        }
      }
    }
    setHours(+diff.toFixed(2));
  }, [startDate, endDate]);

  useEffect(() => {
    const diff = endDate.diff(startDate, 'hours', true);
    if (selectedSupervisors.length > 1) {
      setHours(+diff.toFixed(2) * selectedSupervisors.length);
    } else {
      setHours(+diff.toFixed(2));
    }
  }, [selectedSupervisors]);

  const updateDate = (time: any) => {
    setEndDate(moment(startDate).add(time * 60, 'minutes'));
  };

  const onSave = async () => {
    try {
      if (selectedHelpForm || helpFormId) {
        setSaving(true);
        const body: any = Object.assign(
          {
            startDate,
            endDate,
            hours,
            place,
            personsPresent,
            contentOfCare,
            billable,
            clientHelpFormId:
              selectedHelpForm?.id || props.protocol?.clientHelpFormId,
            helpFormId: selectedHelpForm?.helpFormId || helpFormId,
            applyToAllClients,
            timeTrackingId: props.timeTrackingId,
          },
          props.timeTrackingId && {
            timeTrackingId: props.timeTrackingId,
          },
          props.familyId && {
            familyId: props.familyId,
          },
          props.clientId && {
            clientId: props.clientId,
          },
          props.submitOnSave && {
            submitOnSave: props.submitOnSave,
          },
        );

        if (!props.protocol) {
          body.userIds = selectedSupervisors.map((u) => u.id);
        } else {
          if (
            selectedSupervisors.length > 0 &&
            !isEqual(
              selectedSupervisors.map((u) => u.id).sort(),
              props.protocol.supervisorIds?.sort(),
            )
          ) {
            body.userIds = selectedSupervisors.map((u) => u.id);
          }
        }

        let res;

        if (props.protocol) {
          res = await ProtocolApi.updateProtocol(
            context.authToken,
            props.protocol.id,
            body,
          );
        } else {
          res = await ProtocolApi.createProtocol(context.authToken, body);
        }

        if (res.message) {
          snackbarUtils.success(res.message);
        }
        setHoursHelptext('');
        props.onSave && props.onSave();
        props.onClose && props.onClose();
      }
    } catch (e) {
      console.error(e);
      // snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Stack spacing={2} mt={1}>
      {!selectedHelpForm &&
      availableHelpforms.length === 0 &&
      !props.protocol ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'red', fontSize: '1.1em', mb: 1 }}>
            {context.i18n.protocolOnlyWithHelpform}
          </Typography>

          {context.isMobile ? (
            <Typography sx={{ color: 'red', fontSize: '1.1em', mb: 1 }}>
              <b>({context.i18n.onlyDesktop})</b>
            </Typography>
          ) : (
            <Link
              href={
                props.clientId
                  ? `/clients/${props.clientId}/${ClientTabType.DATA}/${ClientSubTabType.CLIENT_DATA_HELP_FORM}`
                  : `/families/${props.familyId}/${FamilyTabType.FAMILY_DATA}/${FamilySubTabType.FAMILY_HELPFORM}`
              }
              target="_blank"
              onClick={props.onClose ? props.onClose : undefined}
              sx={{ color: 'red' }}
            >
              <Button variant="contained" color="error">
                {context.i18n.createHelpform}
              </Button>
            </Link>
          )}
        </Box>
      ) : (
        <>
          {!props.protocol &&
            (context.isAdmin || availableHelpforms.length > 1) && (
              <span>
                <Autocomplete
                  disablePortal
                  value={selectedHelpForm}
                  noOptionsText={context.i18n.noHelpForms}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  getOptionLabel={(option: any) => option.name || ''}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label={context.i18n.helpForm} />
                    );
                  }}
                  onChange={(e, v) => {
                    setSelectedHelpForm(v);
                    setSelectedSupervisors([]);
                  }}
                  options={availableHelpforms}
                  disabled={props.disabled}
                />
              </span>
            )}

          {allowSupervisorChange &&
          selectedHelpForm &&
          selectedHelpForm.users &&
          ((selectedHelpForm.users.length === 1 &&
            !selectedSupervisors.find((u) => u.id === context.userId)) ||
            selectedHelpForm.users.length > 1) ? (
            <Autocomplete
              noOptionsText={context.i18n.noSupervisorOptions}
              renderInput={(params) => (
                <TextField
                  error={
                    applyToAllClients &&
                    (selectedSupervisors
                      ? selectedSupervisors?.length > 1
                      : false)
                  }
                  helperText={
                    applyToAllClients &&
                    (selectedSupervisors
                      ? selectedSupervisors?.length > 1
                      : false)
                      ? context.i18n.only1Supervisor
                      : ''
                  }
                  {...params}
                  label={context.i18n.supervisor}
                />
              )}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              value={selectedSupervisors}
              getOptionLabel={(o) => o.name}
              onChange={(e, v) => setSelectedSupervisors(v)}
              multiple
              options={
                selectedHelpForm && selectedHelpForm.users
                  ? selectedHelpForm.users
                  : []
              }
              disablePortal
              disabled={props.disabled}
            />
          ) : (
            props.protocol &&
            props.protocol.supervisors &&
            props.protocol.supervisors.length > 0 && (
              <Typography
                sx={{
                  fontSize: { xs: '0.8rem !important', md: '1rem !important' },
                  pb: 2,
                }}
              >
                {context.i18n.supervisor}:<br />
                {props.protocol.supervisors.map((s) => s.fullName).join(', ')}
              </Typography>
            )
          )}

          <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
            <DateTimeRangePicker
              format="DD.MM.YYYY HH:mm"
              value={[startDate, endDate]}
              sx={{ width: '100%' }}
              onChange={(dates: any, ctx: any) => {
                setStartDate(dates[0]);
                if (dates[1]) setEndDate(dates[1]);
                if (props.onDisableSave) {
                  if (ctx.validationError[0]) {
                    props.onDisableSave(true);
                  } else {
                    props.onDisableSave(false);
                  }
                }
              }}
              disabled={props.disabled}
              minutesStep={15}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              localeText={{
                start: context.i18n.startDate,
                end: context.i18n.endDate,
                startDate: context.i18n.startDate,
                endDate: context.i18n.endDate,
                startTime: context.i18n.startTime,
                endTime: context.i18n.endTime,
              }}
            />
          </Stack>
          {selectedHelpForm && (
            <Typography>
              {context.i18n.formatString(
                context.i18n.protocolTimeRemaining,
                selectedHelpForm.hoursUsed || '0',
                selectedHelpForm.hoursMax || '-',
              )}
              <Typography sx={{ ml: 2 }} color={'grey.500'} variant="caption">
                {context.i18n.protocolTimeRemainingInfoText}
              </Typography>
            </Typography>
          )}

          <TextField
            onChange={(e) => {
              updateDate(e.currentTarget.value);
              setHours(e.currentTarget.value);
            }}
            disabled={props.disabled}
            value={hours}
            type={'number'}
            inputProps={{
              step: 0.25,
              min: 0,
            }}
            helperText={hoursHelptext}
            label={context.i18n.hours}
          />
          <Stack spacing={1} direction="row">
            <FormControl disabled={props.disabled}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={!billable}
                    onClick={() => setbillable(!billable)}
                  />
                }
                label={context.i18n.notbillable}
              />
            </FormControl>
            {props.protocol === undefined && (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={applyToAllClients}
                      onClick={() => setApplyToAllClients(!applyToAllClients)}
                    />
                  }
                  label={context.i18n.applyToAllClients}
                />
              </FormControl>
            )}
          </Stack>
          <TextField
            value={place}
            disabled={props.disabled}
            onChange={(e) => {
              setPlace(e.currentTarget.value);
            }}
            label={context.i18n.place}
          />
          <TextField
            value={personsPresent}
            disabled={props.disabled}
            onChange={(e) => {
              setPersonsPresent(e.currentTarget.value);
            }}
            label={context.i18n.personsPresent}
          />
          <FormGroup>
            <WysiwygEditor
              value={contentOfCare}
              onChange={(v) => {
                setContentOfCare(v);
              }}
              label={context.i18n.contentOfCare}
              disabled={props.disabled}
            />
          </FormGroup>
        </>
      )}
    </Stack>
  );
};

export default ProtocolFields;
