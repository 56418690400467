import { Box, Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormElement } from '../../../client';
import {
  ClientDataType,
  FormFieldType,
  ReferencePersonRole,
  RelationshipType,
  Sex,
} from '@casecare/types';
import { FormFieldStyle } from '../../../../types/form-element-props';
import { useParams } from 'react-router-dom';
import { sortEnumWithLanguage } from '../../../../utils/core/misc-utils';

interface EcoMapClientDataCreateProps {}

const EcoMapClientDataCreate: FC<EcoMapClientDataCreateProps> = (props) => {
  const context = useContext(AppContext);
  const client = {
    [ClientDataType.FIRST_NAME]: '',
    [ClientDataType.LAST_NAME]: '',
    [ClientDataType.SEX]: Sex.MALE,
    relationShip: '',
    role: '',
    isDead: undefined,
    saveInFamily: undefined,
    isRealPerson: 1,
    referencePerson: true,
  };
  const groupId = 'Eco-Map-Client-Data-' + 'CREATE';

  const [isFamily, setIsFamily] = useState(false);
  const [isReference, setIsReference] = useState(false);

  useEffect(() => {
    context.getAndRemoveGroupData(groupId);
  }, []);

  return (
    <>
      <Box sx={{ m: -2 }}>
        <Grid container>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT}
              value={client[ClientDataType.FIRST_NAME]}
              title={context.i18n.firstName}
              id={ClientDataType.FIRST_NAME}
              groupId={groupId}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT}
              value={client[ClientDataType.LAST_NAME]}
              title={context.i18n.lastName}
              id={ClientDataType.LAST_NAME}
              groupId={groupId}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.DROPDOWN}
              value={client[ClientDataType.SEX]}
              title={context.i18n.sex}
              id={ClientDataType.SEX}
              fieldOptions={Object.entries(Sex).map((sex) => {
                return { key: sex[0], label: context.i18n[sex[0]] };
              })}
              style={FormFieldStyle.NORMAL}
              groupId={groupId}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.RADIO}
              value={client.isRealPerson}
              title={''}
              id={'isRealPerson'}
              fieldOptions={[
                {
                  key: 1,
                  label: context.i18n.realPerson,
                },
                {
                  key: 2,
                  label: context.i18n.fictionalPerson,
                },
              ]}
              groupId={groupId}
            />
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.CHECKBOX}
              value={client.isDead}
              title={''}
              id={'isDead'}
              fieldOptions={[
                {
                  key: 0,
                  label: context.i18n.alreadyDead,
                },
              ]}
              groupId={groupId}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.DROPDOWN}
              value={client.relationShip}
              title={context.i18n.relationShip}
              id={'relationship'}
              fieldOptions={sortEnumWithLanguage(RelationshipType)}
              onChange={(value) => {
                if (value) setIsFamily(true);
                else setIsFamily(false);
              }}
              style={FormFieldStyle.NORMAL}
              groupId={groupId}
              disabled={isReference}
            />
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT}
              value={client.role}
              title={context.i18n.role}
              id={'role'}
              style={FormFieldStyle.NORMAL}
              disabled={isFamily}
              groupId={groupId}
              onChange={(value) => {
                if (value) setIsReference(true);
                else setIsReference(false);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT_LARGE}
              value={''}
              title={context.i18n.additionalNotes}
              id={ClientDataType.NOTES}
              groupId={groupId}
              style={FormFieldStyle.OUTLINED}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.CHECKBOX}
                value={client.saveInFamily}
                title={''}
                id={'isInFamily'}
                fieldOptions={[
                  {
                    key: 0,
                    label: context.i18n.isInFamily,
                  },
                ]}
                groupId={groupId}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EcoMapClientDataCreate;
