import { ReferencePersonRole, RelationshipType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export default class StorageApi {
  static listWithoutParent(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('storage/list', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static list(
    token: string | undefined,
    storageItemId: string,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('storage/list/' + storageItemId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static delete(
    token: string | undefined,
    storageItemId: string,
  ): Promise<any> {
    return axiosInstance
      .delete('storage/' + storageItemId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static edit(
    token: string | undefined,
    storageItemId: string,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('storage/' + storageItemId, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static breadCrumb(
    token: string | undefined,
    storageItemId: string,
    storageItemIdTo?: string,
  ): Promise<any> {
    return axiosInstance
      .get(
        'storage/bread-crumb/' +
          storageItemId +
          (storageItemIdTo ? '/' + storageItemIdTo : ''),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static uploadFiles(
    token: string | undefined,
    storageItemId: string,
    files: any,
  ): Promise<any> {
    const formData = new FormData();
    for (const file of files) formData.append('files', file);
    return axiosInstance
      .post('storage/upload/' + storageItemId, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createFolder(
    token: string | undefined,
    storageItemId: string,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('storage/folder/' + storageItemId, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static downloadFile(
    token: string | undefined,
    storageItemId: string,
  ): Promise<any> {
    return axiosInstance
      .get('storage/download/' + storageItemId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const match =
          res.headers['content-disposition'].match(/filename="([^"]+)"/);

        return { ...res, fileName: match ? match[1] : undefined };
      });
  }

  static replaceFile(
    token: string | undefined,
    storageItemId: string,
    file: any,
  ): Promise<any> {
    const formData = new FormData();
    formData.append('files', file);
    return axiosInstance
      .post('storage/replace/' + storageItemId, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static uploadFolder(
    token: string | undefined,
    storageItemId: string,
    files: any,
  ): Promise<any> {
    const formData = new FormData();
    for (const file of files) formData.append('files', file);
    return axiosInstance
      .post('storage/upload/' + storageItemId, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  static setPermissions(
    token: string | undefined,
    storageItemId: string,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('storage/set-permissions/' + storageItemId, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  }
}
