import moment from 'moment';

export const parseDateTimeString = (
  dateString: string | undefined,
  overrideFormat?: string,
): string => {
  if (!dateString) return '';
  return moment(dateString).format(overrideFormat || 'DD.MM.YYYY | HH:mm');
};

export const parseDateString = (dateString: string | undefined): string => {
  if (!dateString) return '';
  return moment(dateString).format('DD.MM.YYYY');
};

export const parseDurationNumber = (
  value: number | undefined,
  includeSeconds?: boolean,
): string => {
  if (!value) return '';
  if (includeSeconds) return moment.utc(value).format('HH[h] mm[m] ss[s]');
  else return moment.utc(value).format('HH[h] mm[m]');
};
