import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

interface CountUpTimerProps {
  startTime: number | undefined;
  interval?: number;
}

const CountUpTimer = (props: CountUpTimerProps): JSX.Element => {
  const [diff, setDiff] = useState(0);

  const timer = () => {
    let timeDiff = 0;
    if (props.startTime) {
      timeDiff = moment().unix() - props.startTime;
    }
    setDiff(timeDiff);
  };

  useEffect(() => {
    timer();
    const intervalId = setInterval(() => {
      timer();
    }, props.interval ?? 1000);

    //remove interval after component dismiss
    return () => clearInterval(intervalId);
  }, [props.startTime]);

  return (
    <>
      {moment
        .utc(moment.duration(props.startTime ? diff : 0, 's').as('ms'))
        .format('HH:mm:ss')}
    </>
  );
};

export default CountUpTimer;
