export enum ClientTabType {
  DATA = 'clientData',
  RISK = 'risk',
  DOCUMENTATION = 'documentation',
  ID = 'id',
  PERMISSIONS = 'permissions',
  ADD = 'add',
  SAVE = 'save',
  NOTES = 'notes',
  SOCIAL_ENVIRONMENT = 'social',
  SOCIAL_DIAGNOSTIC = 'social-diagnostic',
  STORAGE = 'storage',
  TODO = 'todo',
  TIME_TRACKING = 'timeTracking',
}
