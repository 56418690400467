import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import moment from 'moment';
import { TimeTrackingStatus } from '../../../../types/time-tracking-status';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TimeTrackingCategory } from '../../../../types/time-tracking-category';
import { TimeTrackingApi } from '../../../../api';
import { useQuery } from 'react-query';

interface TimeTrackingEntryDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingEntry?: TimeTrackingEntry;
  timeTrackingCategories: TimeTrackingCategory[];
  clientFamilyList: any[];
  userId?: string;
}

const TimeTrackingEntryDialog: FC<TimeTrackingEntryDialogProps> = (props) => {
  const context = useContext(AppContext);
  const [defaultValue, setDefaultValue] = useState<TimeTrackingEntry>({
    status: TimeTrackingStatus.OPEN,
    start: moment(),
    createdManually: true,
    categoryId: '',
  });
  const [timeTrackingEntry, setTimeTrackingEntry] =
    useState<TimeTrackingEntry>(defaultValue);
  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);

  useEffect(() => {
    if (props.timeTrackingEntry) setTimeTrackingEntry(props.timeTrackingEntry);
  }, [props.timeTrackingEntry]);

  useEffect(() => {
    if (props.userId) {
      setDefaultValue({ ...defaultValue, userId: props.userId });
      setTimeTrackingEntry({ ...timeTrackingEntry, userId: props.userId });
    }
  }, [props.userId]);

  useEffect(() => {
    if (props.timeTrackingCategories)
      setTimeTrackingCategories(props.timeTrackingCategories);
  }, [props.timeTrackingCategories]);

  useEffect(() => {
    if (props.clientFamilyList) setClientFamilyList(props.clientFamilyList);
  }, [props.clientFamilyList]);

  const onClose = async () => {
    props.onClose();
  };

  const onSave = async () => {
    try {
      let res = null;
      if (timeTrackingEntry.id)
        res = await TimeTrackingApi.editEntry(
          context.authToken,
          timeTrackingEntry.id,
          timeTrackingEntry,
        );
      else
        res = await TimeTrackingApi.createEntry(
          context.authToken,
          timeTrackingEntry,
        );
      if (res.message) {
        snackbarUtils.success(res.message);
        setTimeTrackingEntry(defaultValue);
        onClose();
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={props.open} onClose={onClose}>
      <DialogTitle>
        {props.timeTrackingEntry
          ? context.i18n.timeTrackingEntryEdit
          : context.i18n.timeTrackingEntryCreate}
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item md={6} xs={12}>
            <DatePicker
              format="DD.MM.YYYY"
              views={['day', 'year', 'month']}
              label={context.i18n.timeTrackingDate}
              value={moment(timeTrackingEntry.start)}
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  start: value,
                })
              }
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingStatus}
                />
              )}
              getOptionLabel={(option) =>
                context.i18n['timeTrackingStatus_' + option]
              }
              options={Object.keys(TimeTrackingStatus).map((e) => e)}
              value={timeTrackingEntry.status}
              onChange={(e, val) => {
                if (val)
                  setTimeTrackingEntry({
                    ...timeTrackingEntry,
                    status: val as TimeTrackingStatus,
                  });
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TimePicker
              format="HH:mm"
              views={['hours', 'minutes']}
              label={context.i18n.timeTrackingStart}
              value={moment(timeTrackingEntry.start)}
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  start: value,
                })
              }
              ampm={false}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TimePicker
              format="HH:mm"
              views={['hours', 'minutes']}
              label={context.i18n.timeTrackingEnd}
              value={
                timeTrackingEntry.end ? moment(timeTrackingEntry.end) : null
              }
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  end: value,
                })
              }
              ampm={false}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={
                timeTrackingEntry.category &&
                timeTrackingEntry.category.deletionDate
                  ? true
                  : false
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingCategory}
                />
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (value.id) return option.id == value.id;
                else return false;
              }}
              options={timeTrackingCategories}
              groupBy={(option: any) => option.parent.name}
              value={timeTrackingEntry.category ?? null}
              onChange={(e, val) => {
                if (val)
                  setTimeTrackingEntry({
                    ...timeTrackingEntry,
                    category: val,
                    categoryId: val.id ?? '',
                  });
              }}
            />
          </Grid>
          {timeTrackingEntry.category?.isClientOrFamilyNeeded && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded
                    }
                  />
                )}
                groupBy={(option) => {
                  if (option.isFamily) return context.i18n.family;
                  return context.i18n.client;
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  if (value.id) return option.id == value.id;
                  else return false;
                }}
                onChange={(e, val) => {
                  if (val) {
                    if (val.isFamily)
                      setTimeTrackingEntry({
                        ...timeTrackingEntry,
                        familyId: val.id,
                        family: val,
                        clientId: undefined,
                        client: undefined,
                      });
                    else
                      setTimeTrackingEntry({
                        ...timeTrackingEntry,
                        clientId: val.id,
                        client: val,
                        familyId: undefined,
                        family: undefined,
                      });
                  }
                }}
                value={
                  timeTrackingEntry.family ?? timeTrackingEntry.client ?? null
                }
                options={clientFamilyList.sort((x) => (x.isFamily ? -1 : 1))}
              />
            </Grid>
          )}
        </Grid>
        {/* Object.entries(Sex).map((sex) => (
                      <MenuItem
                        key={sex[1]}
                        value={sex[1]}
                        onClick={() => {
                          field.setValue(sex[0]);
                          setSexError('');
                        }}
                      >
                        {context.i18n[sex[0]]}
                      </MenuItem>
                    ))} */}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimeTrackingEntryDialog;
