export enum FamilyTabType {
  FAMILY_MEMBERS = 'familyMembers',
  REFERENCE_PERSONS = 'referencePersons',
  FAMILY_DATA = 'familyData',
  DOCUMENTATION = 'documentation',
  STORAGE = 'storage',
  PERMISSIONS = 'permissions',
  TODO = 'todo',
  TIME_TRACKING = 'timeTracking',
}
