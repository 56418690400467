import {
  faBoxArchive,
  faBoxOpen,
  faDownload,
  faEdit,
  faExchange,
  faExclamationCircle,
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileText,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faLock,
  faPlus,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Tooltip,
  SvgIcon,
  IconButton,
  Link,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Breadcrumbs,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import {
  GridColDef,
  DataGridPro,
  GridSortModel,
  GridPaginationModel,
} from '@mui/x-data-grid-pro';
import {
  FC,
  useContext,
  useState,
  useEffect,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import { useQuery } from 'react-query';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  ClientApi,
  ExportApi,
  FamilyApi,
  StorageApi,
  UserApi,
} from '../../../api';
import { AppContext } from '../../../hooks/context';
import { Client, ClientDataGridElement } from '../../../types';
import { parseDateTimeString, queryClient } from '../../../utils';
import { ClientExportType } from '@casecare/types';
import { ClientArchiveDialog } from '../../dialogs';
import {
  ClientHelpFormListColors,
  DragAndDropFileUpload,
} from '../../../utils/styling/styling';
import moment from 'moment';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { StorageItem } from '../../../types/storage-item';
import { StorageItemType } from '../../../types/storage-item-type';
import CreateStorageFolderDialog from '../../dialogs/create-storage-folder-dialog/create-storage-folder-dialog';
import ConfirmDialog from '../../dialogs/confirm-dialog/confirm-dialog';
import DeleteDialog from '../../dialogs/delete-dialog.tsx/delete-dialog';
import EditStorageDialog from '../../dialogs/edit-storage-dialog/edit-storage-dialog';
import { StorageItemPermissionType } from '../../../types/storage-item-permission-type';
import StorageItemPermissionDialog from '../../dialogs/storage-item-permission-dialog/storage-item-permission-dialog';
import { StorageItemFolderType } from '../../../types/storage-item-folder-type';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';

interface StorageTableProps {
  storageItemId: string;
  openFolder: (params: any) => void;
}
const StorageTable: FC<StorageTableProps> = (props) => {
  const context = useContext(AppContext);

  const [openCreateStorageFolderDialog, setOpenCreateStorageFolderDialog] =
    useState(false);

  const [rows, setRows] = useState<ClientDataGridElement[]>([]);

  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'name', sort: 'asc' },
    { field: 'type', sort: 'desc' },
    { field: 'mimeType', sort: 'desc' },
  ]);
  const [searchText, setSearchText] = useState('');

  const [archived, setArchived] = useState(false);
  const [storageItemFolderType, setStorageItemFolderType] =
    useState<StorageItemFolderType | null>(null);

  const [rowCount, setRowCount] = useState(0);

  const [isDragAndDrop, setIsDragAndDrop] = useState(false);

  const [addMenu, setAddMenu] = useState<null | HTMLElement>(null);
  const addMenuOpen = Boolean(addMenu);

  const [selectedStorageItem, setSelectedStorageItem] =
    useState<null | StorageItem>(null);

  const inputUploadFile = useRef<HTMLInputElement | null>(null);

  const inputUploadFolder = useRef<HTMLInputElement | null>(null);

  const inputReplaceFile = useRef<HTMLInputElement | null>(null);
  const [openReplaceConfirmDialog, setOpenReplaceConfirmDialog] =
    useState(false);
  const [selectedReplaceFile, setSelectedReplaceFile] = useState<null | any>(
    null,
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const mimeTypeToIcon = (mimeType: string) => {
    //copy from https://gist.github.com/colemanw/9c9a12aae16a4bfe2678de86b661d922
    const icons: Record<string, any> = {
      // Media
      image: faFileImage,
      audio: faFileAudio,
      video: faFileVideo,
      // Documents
      'application/pdf': faFilePdf,
      'application/msword': faFileWord,
      'application/vnd.ms-word': faFileWord,
      'application/vnd.oasis.opendocument.text': faFileWord,
      'application/vnd.openxmlformats-officedocument.wordprocessingml':
        faFileWord,
      'application/vnd.ms-excel': faFileExcel,
      'application/vnd.openxmlformats-officedocument.spreadsheetml':
        faFileExcel,
      'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
      'application/vnd.ms-powerpoint': faFilePowerpoint,
      'application/vnd.openxmlformats-officedocument.presentationml':
        faFilePowerpoint,
      'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
      'text/plain': faFileText,
      'text/html': faFileCode,
      'application/json': faFileCode,
      // Archives
      'application/gzip': faFileArchive,
      'application/zip': faFileArchive,
      folder: faFolder,
      default: faFile,
    };

    return icons[mimeType] ?? icons['default'];
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'storageItems',
      paginationModel,
      archived,
      sortModel,
      props.storageItemId,
      searchText,
    ],
    queryFn: () => {
      const data: any = Object.assign({
        archived: archived,
        pageNum: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sortModel,
        searchText,
      });
      return StorageApi.list(context.authToken, props.storageItemId, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(
          data.data.collection.map((storageItem: StorageItem) => {
            return Object.assign({
              ...storageItem,
              mimeType: storageItem.mimeType ?? 'folder',
            });
          }),
        );
        setRowCount(data.data.totalCount);
        setStorageItemFolderType(data.data.storageItemFolderType);
      }
    }
  }, [data, error, isLoading]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.name,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <SvgIcon sx={{ height: '2rem', mr: 1 }}>
            <FontAwesomeIcon
              icon={mimeTypeToIcon(params.row.mimeType)}
            ></FontAwesomeIcon>
          </SvgIcon>
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },
    {
      field: 'createdBy',
      headerName: context.i18n.createdBy,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Typography>{params.row.createdBy ?? '-'}</Typography>
        </Box>
      ),
    },
    {
      field: 'lastModifiedBy',
      headerName: context.i18n.lastEditedBy,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Typography>{params.row.lastModifiedBy ?? '-'}</Typography>
        </Box>
      ),
    },
    {
      field: 'createdDateTime',
      headerName: context.i18n.creationDate,
      flex: 1.5,
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Typography>
            {params.row.createdDateTime
              ? moment(params.row.createdDateTime).format(
                  'DD.MM.YYYY | HH:mm:ss',
                )
              : '-'}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'lastModifiedDateTime',
      headerName: context.i18n.lastEditDate,
      flex: 1.5,
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ alignItems: 'center', display: 'flex' }} tabIndex={0}>
          <Typography>
            {params.row.lastModifiedDateTime
              ? moment(params.row.lastModifiedDateTime).format(
                  'DD.MM.YYYY | HH:mm:ss',
                )
              : '-'}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'action',
      headerName: '',
      minWidth: 250,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <Tooltip title={context.i18n.storageAddMenu}>
            <IconButton
              disableRipple
              color="primary"
              onClick={(event) => {
                setAddMenu(event.currentTarget);
              }}
              id="storage-add-menu-button"
              aria-controls={addMenuOpen ? 'storage-add-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={addMenuOpen ? 'true' : undefined}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
          <Menu
            id="storage-add-menu-menu"
            aria-labelledby="storage-add-menu"
            anchorEl={addMenu}
            open={addMenuOpen}
            onClose={() => {
              setAddMenu(null);
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                setAddMenu(null);
                setOpenCreateStorageFolderDialog(true);
              }}
            >
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon={faFolderPlus}></FontAwesomeIcon>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText>{context.i18n.createFolder}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAddMenu(null);
                if (inputUploadFile.current) inputUploadFile.current.click();
              }}
            >
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon={faFileUpload}></FontAwesomeIcon>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText>{context.i18n.uploadFile}</ListItemText>
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                setAddMenu(null);
                if (inputUploadFolder.current)
                  inputUploadFolder.current.click();
              }}
            >
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText>{context.i18n.uploadFolder}</ListItemText>
            </MenuItem> */}
          </Menu>
        </>
      ),
      renderCell: (params) => {
        const permissionType = params.row.userPermission?.type ?? null;
        let canEdit = false;
        let canDelete = false;
        if (archived) {
          canEdit = context.isAdmin && !params.row.namedBySystem;
          canDelete =
            context.isAdmin &&
            params.row.type == StorageItemType.FILE &&
            !params.row.folderType;
        } else {
          canEdit =
            (permissionType == StorageItemPermissionType.OWNER ||
              permissionType == StorageItemPermissionType.EDITOR) &&
            !params.row.namedBySystem;
          canDelete =
            params.row.type == StorageItemType.FILE &&
            !params.row.folderType &&
            permissionType == StorageItemPermissionType.OWNER;
        }

        return (
          <>
            {params.row.type == StorageItemType.FOLDER && (
              <Tooltip title={context.i18n.openFolder}>
                <IconButton
                  disableRipple
                  color="primary"
                  onClick={() => openFolder(params)}
                >
                  <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            )}
            {params.row.type == StorageItemType.FILE && (
              <>
                <Tooltip title={context.i18n.downloadFile}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() => downloadFile(params.row.id)}
                  >
                    <FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon>
                  </IconButton>
                </Tooltip>
                {canEdit && (
                  <Tooltip title={context.i18n.replaceFile}>
                    <IconButton
                      disableRipple
                      color="primary"
                      onClick={() => {
                        setSelectedStorageItem(params.row);
                        if (inputReplaceFile.current)
                          inputReplaceFile.current.click();
                      }}
                    >
                      <FontAwesomeIcon icon={faExchange}></FontAwesomeIcon>
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {canEdit && (
              <Tooltip title={context.i18n.edit}>
                <IconButton
                  disableRipple
                  color="primary"
                  onClick={() => {
                    setSelectedStorageItem(params.row);
                    setOpenEditDialog(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={context.i18n.edit}>
              <IconButton
                disableRipple
                color="primary"
                onClick={() => {
                  setSelectedStorageItem(params.row);
                  setOpenPermissionDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
              </IconButton>
            </Tooltip>
            {canDelete && (
              <Tooltip title={context.i18n.delete}>
                <IconButton
                  disableRipple
                  color="error"
                  onClick={() => {
                    setSelectedStorageItem(params.row);
                    setOpenDeleteDialog(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  function dragAndDropRemoveDefaultEvent(event: any, isDragAndDrop: boolean) {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.contains(event.relatedTarget)) return;
    setIsDragAndDrop(isDragAndDrop);
  }

  function handleDragAndDropFiles(event: any) {
    dragAndDropRemoveDefaultEvent(event, false);
    if (!event.dataTransfer) return;
    handleFiles(event.dataTransfer.files);
    setIsDragAndDrop(false);
  }

  const showArchivedSwitch = () => {
    switch (storageItemFolderType) {
      case StorageItemFolderType.CLIENT_FAMILY:
        return (
          <FormControlLabel
            control={
              <Switch
                size="medium"
                checked={archived}
                onChange={(ele, value) => setArchived(value)}
              />
            }
            label={context.i18n.archivedSwitchClientFamily}
          />
        );
      case StorageItemFolderType.ADMIN:
      case StorageItemFolderType.MEMBER:
        if (context.isAdmin)
          return (
            <FormControlLabel
              control={
                <Switch
                  size="medium"
                  checked={archived}
                  onChange={(ele, value) => setArchived(value)}
                />
              }
              label={context.i18n.archivedSwitchAdmin}
            />
          );
        else return <></>;

      default:
        return <></>;
    }
  };

  const handleFiles = (fileList: any) => {
    if (fileList && !loading) {
      setLoading(true);
      const files = Array.from(fileList);
      StorageApi.uploadFiles(context.authToken, props.storageItemId, files)
        .then((res: any) => {
          if (res.message) {
            snackbarUtils.success(res.message);
            refetch();
          }
          setLoading(false);
        })
        .catch((res: any) => {
          setLoading(false);
        });
    }
  };

  const handleFolder = (fileList: any) => {
    //console.log(fileList);
    // if (fileList) {
    //   const files = Array.from(fileList);
    //   StorageApi.uploadFolder(
    //     context.authToken,
    //     props.storageItemId,
    //     files,
    //   ).then((res: any) => {
    //     if (res.message) {
    //       snackbarUtils.success(res.message);
    //       refetch();
    //     }
    //   });
    // }
  };

  const replaceFile = (file: any, id: string) => {
    StorageApi.replaceFile(context.authToken, id, file).then((res: any) => {
      if (res.message) {
        snackbarUtils.success(res.message);
        refetch();
      }
    });
  };

  const openFolder = (params: any) => {
    if (params.row.type == StorageItemType.FOLDER) {
      props.openFolder(params);
    }
  };

  const downloadFile = async (id: string) => {
    try {
      const res = await StorageApi.downloadFile(context.authToken, id);
      // create file link in browser's memory
      const href = URL.createObjectURL(new Blob([res.data]));

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', res.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Box
      sx={Object.assign(DragAndDropFileUpload, { paddingBottom: 20 })}
      className={isDragAndDrop || loading ? 'drag-and-drop' : ''}
      onDrop={handleDragAndDropFiles}
      onDragLeave={(event) => dragAndDropRemoveDefaultEvent(event, false)}
      onDragOver={(event) => dragAndDropRemoveDefaultEvent(event, true)}
    >
      <input
        type="file"
        disabled={loading}
        multiple
        ref={inputUploadFile}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (event.target.files) {
            handleFiles(event.target.files);
          }
        }}
      />

      <input
        type="file"
        disabled={loading}
        multiple
        ref={(node) => {
          inputUploadFolder.current = node;

          if (node) {
            ['webkitdirectory', 'directory', 'mozdirectory'].forEach((attr) => {
              node.setAttribute(attr, '');
            });
          }
        }}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (event.target.files) {
            handleFolder(event.target.files);
          }
        }}
      />

      <input
        type="file"
        ref={inputReplaceFile}
        style={{ display: 'none' }}
        disabled={loading}
        onChange={(event) => {
          if (
            event.target.files &&
            event.target.files[0] &&
            selectedStorageItem != null
          ) {
            if (selectedStorageItem.mimeType == event.target.files[0].type) {
              replaceFile(event.target.files[0], selectedStorageItem.id);
              setSelectedStorageItem(null);
            } else {
              setSelectedReplaceFile(event.target.files[0]);
              setOpenReplaceConfirmDialog(true);
            }
          }
        }}
      />
      <Box
        sx={{
          ml: 5,
          mr: 5,
          mb: 1,
        }}
      >
        {showArchivedSwitch()}
      </Box>

      <Box className={'drag-and-drop-container'}>
        <Box className={'drag-and-drop-outer'}></Box>
        <Box className={'drag-and-drop-inner'}>
          <Box className={'drag-and-drop-content'}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <SvgIcon>
                  <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                </SvgIcon>
                <Typography>{context.i18n.DragAndDropFileUpload}</Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <DataGridPro
        sx={{
          fontSize: 17,
          ml: 5,
          mr: 5,
        }}
        // slotProps={{
        //   toolbar: props.mobile
        //     ? {
        //         searchText,
        //         setSearchText: setSearchText,
        //       }
        //     : {},
        // }}
        // slots={
        //   props.mobile
        //     ? {
        //         toolbar: ClientFilterToolBar,
        //       }
        //     : {}
        // }
        columns={tableColumns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        columnBufferPx={4}
        initialState={{
          pagination: { paginationModel: paginationModel },
        }}
        pagination
        // Pagination settings
        paginationMode="client"
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        rowCount={rowCount}
        // End Pagination settings
        // Sorting settings
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        // End sorting settings
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        autoHeight
        onRowDoubleClick={openFolder}
      />
      <CreateStorageFolderDialog
        open={openCreateStorageFolderDialog}
        storageItemId={props.storageItemId}
        onClose={() => {
          refetch();
          setOpenCreateStorageFolderDialog(false);
        }}
      />
      <ConfirmDialog
        open={openReplaceConfirmDialog}
        title={context.i18n.replaceFileDialogTitle}
        content={
          <Typography>{context.i18n.replaceFileDialogContent}</Typography>
        }
        onCancel={() => {
          setSelectedReplaceFile(null);
          setSelectedStorageItem(null);
          setOpenReplaceConfirmDialog(false);
        }}
        onConfirm={() => {
          if (selectedStorageItem != null)
            replaceFile(selectedReplaceFile, selectedStorageItem.id);
          setSelectedReplaceFile(null);
          setSelectedStorageItem(null);
          setOpenReplaceConfirmDialog(false);
        }}
      ></ConfirmDialog>

      <DeleteDialog
        open={openDeleteDialog}
        title={context.i18n.deleteStorageDialogTitle}
        content={
          <Typography>
            {context.i18n.formatString(
              context.i18n.deleteStorageDialogContent,
              selectedStorageItem?.name ?? '',
            )}
          </Typography>
        }
        onCancel={() => {
          setOpenDeleteDialog(false);
          setSelectedStorageItem(null);
        }}
        onDelete={() => {
          setOpenDeleteDialog(false);
          if (selectedStorageItem)
            StorageApi.delete(context.authToken, selectedStorageItem.id).then(
              (res: any) => {
                if (res.message) {
                  snackbarUtils.success(res.message);
                  refetch();
                }
              },
            );
          setSelectedStorageItem(null);
        }}
      ></DeleteDialog>

      <EditStorageDialog
        open={openEditDialog}
        storageItem={selectedStorageItem}
        onClose={() => {
          refetch();
          setOpenEditDialog(false);
        }}
      ></EditStorageDialog>

      <StorageItemPermissionDialog
        open={openPermissionDialog}
        storageItem={selectedStorageItem}
        onClose={() => {
          refetch();
          setOpenPermissionDialog(false);
        }}
      ></StorageItemPermissionDialog>
    </Box>
  );
};

export default StorageTable;
