import { FC, useEffect, useRef, useState } from 'react';

import { AvatarColors, AvatarPropType } from '@casecare/types';
import { Box, CircularProgress, Fade } from '@mui/material';

interface AvatarCanvasProps {
  propType?: AvatarPropType;
  elements: Partial<Record<AvatarPropType, any>>;
  colors: Record<AvatarColors, string>;
  save?: boolean;
  onSave: (pngFile: File, iconFile: File) => void;
}

const AvatarCanvas: FC<AvatarCanvasProps> = (props) => {
  const avatarCanvas = useRef<HTMLCanvasElement>(null);
  const [canvasContext, setCanvasContext] =
    useState<CanvasRenderingContext2D | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    renderCanvas(canvasContext);
  }, [props.colors, props.elements]);

  useEffect(() => {
    renderCanvas(canvasContext);
  }, [canvasContext]);

  useEffect(() => {
    if (props.save) {
      generatePNG();
    }
  }, [props.save]);

  const generatePNG = async () => {
    if (avatarCanvas.current) {
      // Generate PNG image with a different size
      const resizedCanvas = document.createElement('canvas');
      const resizedCtx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 580;
      resizedCanvas.height = 1000;

      if (resizedCtx) await renderCanvas(resizedCtx);

      const pngDataUrl = resizedCanvas.toDataURL();
      const pngBlob = await fetch(pngDataUrl).then((res) => res.blob());
      const pngFile = new File([pngBlob], 'avatar.png', { type: pngBlob.type });

      const imageData = resizedCtx?.getImageData(0, 0, 1000, 1000);

      const iconCanvas = document.createElement('canvas');
      iconCanvas.width = 580;
      iconCanvas.height = 650;
      const iconCtx = iconCanvas.getContext('2d');

      if (iconCtx && imageData) {
        iconCtx.putImageData(imageData, 0, 0);
      }
      const iconDataUrl = iconCanvas.toDataURL();
      const iconBlob = await fetch(iconDataUrl).then((res) => res.blob());
      const iconFile = new File([iconBlob], 'icon.png', {
        type: iconBlob.type,
      });

      props.onSave(pngFile, iconFile);
    }
  };

  useEffect(() => {
    if (avatarCanvas && avatarCanvas.current) {
      const ctx = avatarCanvas.current.getContext('2d');
      if (ctx) {
        setCanvasContext(ctx);
      }
    }
  }, [avatarCanvas]);

  const renderCanvas = async (ctx: CanvasRenderingContext2D | null) => {
    setIsLoading(true);
    const c = ctx || canvasContext;
    if (c && avatarCanvas && avatarCanvas.current) {
      const renders: any = {};
      c.clearRect(0, 0, c.canvas.width, c.canvas.height);
      for (let i = 0; i < Object.values(props.elements).length; i++) {
        renders[i] = await renderElement(c, Object.values(props.elements)[i]);
        renders[i].call();
      }
    }
    setIsLoading(false);
  };

  const renderElement = async (
    ctx: CanvasRenderingContext2D,
    element: string,
  ) => {
    return new Promise((resolve) => {
      fetch(element).then((e) =>
        e.text().then((svgContent) => {
          const DOMURL = window.URL || window.webkitURL || window;
          const img1 = new Image();
          const replacedSVG = svgContent
            .replace('$REPLACE_EYECOLOR', props.colors.EYE)
            .replace('$REPLACE_MOUTHCOLOR', props.colors.MOUTH)
            .replace('$REPLACE_HAIRCOLOR', props.colors.HAIR)
            .replace('$REPLACE_EYEBROWCOLOR', props.colors.EYEBROWS)
            .replace('$REPLACE_BEARDCOLOR', props.colors.BEARD)
            .replace('$REPLACE_SKINCOLOR', props.colors.SKIN)
            .replace('$REPLACE_SHIRTCOLOR', props.colors.SHIRT)
            .replace('$REPLACE_GLASSESCOLOR', props.colors.GLASSES)
            .replace('$REPLACE_PANTSCOLOR', props.colors.PANTS)
            .replace('$REPLACE_SHOECOLOR', props.colors.SHOES);

          //TODO: need some overall fix for the SVGs.

          const svg = new Blob([replacedSVG], { type: 'image/svg+xml' });

          const url = DOMURL.createObjectURL(svg);
          img1.onload = () => {
            const canvasWidth = ctx.canvas.width,
              canvasHeight = ctx.canvas.height;

            const scaleX = canvasWidth / img1.width;
            const scaleY = canvasHeight / img1.height;
            const scale = Math.max(scaleX, scaleY);

            const imageWidth = img1.width * scale;
            const imageHeight = img1.height * scale;

            const imageX = (canvasWidth - imageWidth) / 2;
            const imageY = (canvasHeight - imageHeight) / 2;

            resolve(() =>
              ctx.drawImage(img1, imageX, imageY, imageWidth, imageHeight),
            );
            DOMURL.revokeObjectURL(url);
          };
          img1.src = url;
        }),
      );
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="75vh"
      width="45vh"
    >
      <CircularProgress
        sx={{
          display: isLoading || props.save ? 'flex' : 'none',
          alignSelf: 'center',
        }}
      />
      <Fade in={!isLoading}>
        <canvas
          style={{
            display: !isLoading && !props.save ? 'inherit' : 'none',
          }}
          height="500vh"
          ref={avatarCanvas}
        />
      </Fade>
    </Box>
  );
};

export default AvatarCanvas;
