import { FC, useContext, useEffect, useState } from 'react';
import { BackgroundWave } from '../../../utils/background/background';
import { Box, ClickAwayListener, Toolbar, useMediaQuery } from '@mui/material';
import MenuDrawer from '../menu-drawer/menu-drawer';
import Navbar from '../navbar/navbar';
import { CategoryData } from '../../../types';
import { theme } from '../../../utils';
import { drawerWidthSpaced } from '../../../utils/constants';
import { AppContext } from '../../../hooks/context';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AdminApi, OrgApi, StorageApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import BreadCrumb from '../breadcrumb/breadcrumb';

interface MenuWrapperProps {
  title: string;
  selected?: string;
  subTitle?: string;
  admin?: boolean;
  storage?: boolean;
  billing?: boolean;
  adminBilling?: boolean;
  addClient?: boolean;
  search?: boolean;
  addFamily?: boolean;
  showCategories?: boolean;
  customBreadCrumb?: boolean;
}

const MenuWrapper: FC<MenuWrapperProps> = (props) => {
  const context = useContext(AppContext);
  const [openDrawer, setOpenDrawer] = useState(context.drawerOpen);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onCloseDrawer = () => {
    context.setDrawerOpen(false);
    setOpenDrawer(false);
  };

  const onOpenDrawer = () => {
    context.setDrawerOpen(true);
    setOpenDrawer(true);
  };

  const onChangeDrawer = () => {
    context.setDrawerOpen(!openDrawer);
    setOpenDrawer(!openDrawer);
  };

  const { data, status } = useQuery(
    'adminCategoryData',
    () => AdminApi.getCategories(context.authToken),
    {
      enabled:
        context.authToken !== undefined && props.showCategories !== undefined,
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const storageFolderQuery = useQuery(
    'listWithoutParent',
    () => StorageApi.listWithoutParent(context.authToken),
    {
      enabled: context.authToken !== undefined,
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  return (
    <Box sx={{ overflowX: 'clip' }}>
      <Navbar {...props} open={openDrawer} onDrawerClicked={onChangeDrawer} />
      <MenuDrawer
        onOpenDrawer={onOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        categories={data ? data.data : []}
        storages={
          storageFolderQuery.data ? storageFolderQuery.data.data.folders : []
        }
        open={openDrawer}
        {...props}
      />

      <Box
        ml={
          openDrawer || isSmallScreen
            ? drawerWidthSpaced
            : `calc(${theme.spacing(9)} + 1px)`
        }
        sx={{
          width: `calc(100% - ${drawerWidthSpaced})`,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <BreadCrumb customBreadCrumb={props.customBreadCrumb} />
        {/* TODO: remove margins of every element and put it overall to the sidebar (idk something like this below) */}
        {/* <Box sx={{ ml: 2 }}>{props.children}</Box> */}
        {props.children}
      </Box>
    </Box>
  );
};

export default MenuWrapper;
