import { FC, useContext, useEffect } from 'react';
import { ClientTable } from '../../../components/client';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import { MenuWrapper } from '../../../components/core';
import { useNavigate, useParams } from 'react-router-dom';
import { StorageType } from '@casecare/types';
import { StorageTable } from '../../../components/storage';
import { useQuery } from 'react-query';
import { StorageApi } from '../../../api';
import { BreadCrumbSegment } from '../../../types/breadcrumb-segment';

const StorageList: FC = () => {
  const context = useContext(AppContext);
  const { storageItemIndexId, storageItemFolderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setDocumentTitle(context.i18n.storage);
    return () => loadTitle();
  }, []);

  const breadCrumbQuery = useQuery({
    queryKey: ['storageItemBreadCrumb', storageItemFolderId],
    queryFn: () => {
      return StorageApi.breadCrumb(
        context.authToken,
        storageItemFolderId ?? '',
      );
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!breadCrumbQuery.isLoading) {
      if (breadCrumbQuery.error) {
        console.error(breadCrumbQuery.error);
      } else if (breadCrumbQuery.data && breadCrumbQuery.data.data) {
        const breadCrumbs: BreadCrumbSegment[] = [
          { path: '/storage', name: context.i18n.storage },
        ];
        for (const breadCrumb of breadCrumbQuery.data.data) {
          breadCrumbs.push({
            path: `../${storageItemIndexId}/${breadCrumb.id}`,
            name: breadCrumb.name,
          });
        }
        context.setBreadCrumbSegments(breadCrumbs);
      }
    }
  }, [breadCrumbQuery.data, breadCrumbQuery.error, breadCrumbQuery.isLoading]);

  return (
    <MenuWrapper
      customBreadCrumb
      title={context.i18n.storage}
      storage
      selected={storageItemIndexId}
    >
      <StorageTable
        storageItemId={storageItemFolderId ?? ''}
        openFolder={(params: any) => {
          navigate(`../${storageItemIndexId}/${params.row.id}`);
        }}
      />
    </MenuWrapper>
  );
};

export default StorageList;
