import {
  ClientDataType,
  FormFieldType,
  OtherCharacteristics,
  ReferencePersonRole,
  RelationshipType,
  Sex,
  Sexuality,
  Sickness,
} from '@casecare/types';
import { Box, CircularProgress, Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import client from '../../../api/client/client';
import { FormFieldStyle } from '../../../types/form-element-props';
import { FormDropdown, FormElement } from '../../client';
import { AppContext } from '../../../hooks/context';
import { useQuery } from 'react-query';
import { sortEnumWithLanguage } from '../../../utils/core/misc-utils';

interface GenoGrammClientDataProps {
  client: any;
  isIndex?: boolean;
  isLoading?: boolean;
}

const GenoGrammClientData: FC<GenoGrammClientDataProps> = (props) => {
  const context = useContext(AppContext);

  const [client, setClient] = useState(props.client);
  const [isLoading, setIsLoading] = useState(true);

  const groupId = 'Geno-Gramm-Client-Data-' + (client.id ?? 'CREATE');

  useEffect(() => {
    setClient({ ...props.client });
  }, [props.client]);

  useEffect(() => {
    setIsLoading(props.isLoading || false);
  }, [props.isLoading]);

  useEffect(() => {
    context.getAndRemoveGroupData(groupId);
  }, []);

  return (
    <Box sx={{ m: -2, display: 'flex', justifyContent: 'center' }}>
      {isLoading ? (
        <CircularProgress sx={{ m: 2 }} />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              required
              groupId={groupId}
              type={FormFieldType.TEXT}
              value={client[ClientDataType.FIRST_NAME]}
              title={context.i18n.firstName}
              id={ClientDataType.FIRST_NAME}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              groupId={groupId}
              hideExport
              required
              type={FormFieldType.TEXT}
              value={client[ClientDataType.LAST_NAME]}
              title={context.i18n.lastName}
              id={ClientDataType.LAST_NAME}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={6}>
            <FormElement
              dialog={true}
              groupId={groupId}
              hideExport
              required
              type={FormFieldType.DROPDOWN}
              value={client[ClientDataType.SEX] ?? ''}
              title={context.i18n.sex}
              id={ClientDataType.SEX}
              fieldOptions={Object.entries(Sex).map((sex) => {
                return { key: sex[0], label: context.i18n[sex[0]] };
              })}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={6}>
            <FormElement
              dialog={true}
              groupId={groupId}
              hideExport
              type={FormFieldType.DROPDOWN}
              value={client[ClientDataType.SEXUALITY] ?? ''}
              title={context.i18n.sexuality}
              id={ClientDataType.SEXUALITY}
              fieldOptions={Object.entries(Sexuality).map((s) => {
                return { key: s[0], label: context.i18n[s[0]] };
              })}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          {!props.isIndex && (
            <Grid item xs={12}>
              <FormElement
                dialog={true}
                groupId={groupId}
                hideExport
                type={FormFieldType.CHECKBOX}
                value={client.IS_DEAD && [0]}
                title={''}
                id={ClientDataType.IS_DEAD}
                onChange={() => {
                  setClient({ ...client, IS_DEAD: !client.IS_DEAD });
                }}
                fieldOptions={[
                  {
                    key: 0,
                    label: context.i18n.alreadyDead,
                  },
                ]}
              />
              {client.IS_DEAD && (
                <FormElement
                  groupId={groupId}
                  id={ClientDataType.DEATH_DAY}
                  title={context.i18n.deathDay}
                  value={client[ClientDataType.DEATH_DAY]}
                  hideExport
                  dialog={true}
                  type={FormFieldType.DATE}
                  style={FormFieldStyle.OUTLINED}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <FormElement
              groupId={groupId}
              id={ClientDataType.SICKNESS}
              title={context.i18n.sicknesses}
              value={client[ClientDataType.SICKNESS] ?? []}
              hideExport
              dialog={true}
              type={FormFieldType.DROPDOWN}
              multiple
              style={FormFieldStyle.NORMAL}
              fieldOptions={Object.entries(Sickness).map((role) => {
                return { key: role[0], label: context.i18n[role[0]] };
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              groupId={groupId}
              id={ClientDataType.OTHER_CHARACTERISTICS}
              title={context.i18n.otherCharacteristics}
              value={client[ClientDataType.OTHER_CHARACTERISTICS] ?? []}
              hideExport
              dialog={true}
              type={FormFieldType.DROPDOWN}
              multiple
              style={FormFieldStyle.NORMAL}
              fieldOptions={Object.entries(OtherCharacteristics).map((oc) => {
                return { key: oc[0], label: context.i18n[oc[0]] };
              })}
            />
          </Grid>
          {!props.isIndex && (
            <>
              <Grid item xs={12}>
                <FormElement
                  groupId={groupId}
                  dialog={true}
                  required
                  hideExport
                  type={FormFieldType.DROPDOWN}
                  value={client.relationship?.type ?? ''}
                  title={context.i18n.relationShipIndex}
                  id={'relationship'}
                  fieldOptions={sortEnumWithLanguage(RelationshipType)}
                  style={FormFieldStyle.NORMAL}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default GenoGrammClientData;
