import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, Tab, Tabs, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import { FamilyTabType } from '../../../types';
import { TabStyle } from '../../../utils/styling/styling';
import { DiscardDialog } from '../../dialogs';
import TabLink from '../../core/tab-link/tab-link';

interface FamilyTabsProps {
  showSave: boolean;
  selected?: string;
  familyId: string;
  onChange: (id: string) => void;
  onSave: () => void;
  onDiscard: () => void;
}

const FamilyTabs: FC<FamilyTabsProps> = (props) => {
  const context = useContext(AppContext);

  const [selected, setSelected] = useState('familyData');
  const [openDialog, setOpenDialog] = useState(false);
  const [tmpValue, setTmpValue] = useState('');

  const onContinue = () => {
    setOpenDialog(false);
    setSelected(tmpValue);
  };

  const handleChange = (value: string) => {
    if (value === 'save') {
      props.onSave();
    } else if (props.showSave) {
      setOpenDialog(true);
      setTmpValue(value);
    } else {
      setSelected(value);
      props.onChange(value);
    }
  };

  useEffect(() => {
    if (props.selected) {
      handleChange(props.selected);
    }
  }, [props.selected]);

  const tabs = [
    {
      value: FamilyTabType.TODO,
      title: context.i18n.familyTabTodo,
    },
    {
      value: FamilyTabType.TIME_TRACKING,
      title: context.i18n.familyTabTimeTracking,
    },
    {
      value: FamilyTabType.FAMILY_DATA,
      title: context.i18n.familyTabFamilyData,
    },
    {
      value: FamilyTabType.FAMILY_MEMBERS,
      title: context.i18n.familyTabFamilyMembers,
    },
    {
      value: FamilyTabType.REFERENCE_PERSONS,
      title: context.i18n.familyTabReferencePersons,
    },
    // {
    //   value: FamilyTabType.ARCHIVED,
    //   title: context.i18n.archived,
    // },
    {
      value: FamilyTabType.DOCUMENTATION,
      title: context.i18n.familyTabDocumentation,
    },
    {
      value: FamilyTabType.STORAGE,
      title: context.i18n.familyTabStorage,
    },
    {
      value: FamilyTabType.PERMISSIONS,
      title: context.i18n.familyTabPermissions,
    },
  ];

  return (
    <Box>
      <Tabs
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        sx={{
          m: 0,
          ml: 3,
          mr: 3,
          height: 58,
          alignItems: 'end',
        }}
        value={selected}
        onChange={(e, newVlaue) => {
          handleChange(newVlaue);
        }}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map((tab) => (
          <TabLink
            href={`/families/${props.familyId}/` + tab.value}
            key={tab.value}
            value={tab.value}
            label={
              <Typography color="white" textTransform="none">
                {tab.title}
              </Typography>
            }
            sx={TabStyle}
          />
        ))}
        <Tab
          value="save"
          icon={<FontAwesomeIcon icon={faFloppyDisk} color="white" />}
          sx={{
            bgcolor: 'success.light',
            boxShadow: '2px 3px 6px #00000029;',
            height: 48,
            alignSelf: 'end',
            display: props.showSave ? 'default' : 'none',
          }}
        />
      </Tabs>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DiscardDialog
          onDiscard={() => {
            props.onDiscard();
            onContinue();
          }}
          onSave={() => {
            props.onSave();
            onContinue();
          }}
          onClose={() => setOpenDialog(false)}
        />
      </Dialog>
    </Box>
  );
};

export default FamilyTabs;
