import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FormElement, UserSelect } from '../../client';
import { FormFieldType } from '@casecare/types';
import { EcoMapAddDialogType } from '../../../types/eco-map-add-dialog-type';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { AdminApi } from '../../../api';
import { FormFieldStyle } from '../../../types/form-element-props';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import { TimeTrackingCategoryMethod } from '../../../types/time-tracking-category-method';

interface TimeTrackingCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  parentId?: string;
  timeTrackingCategory?: TimeTrackingCategory;
}

const TimeTrackingCategoryDialog: FC<TimeTrackingCategoryDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);
  const [defaultValue, setDefaultValue] = useState<TimeTrackingCategory>({
    name: '',
    isClientOrFamilyNeeded: false,
    method: undefined,
  });
  const [timeTrackingCategory, setTimeTrackingCategory] =
    useState<TimeTrackingCategory>(defaultValue);

  useEffect(() => {
    if (props.timeTrackingCategory)
      setTimeTrackingCategory(props.timeTrackingCategory);
  }, [props.timeTrackingCategory]);

  useEffect(() => {
    if (props.parentId) {
      setTimeTrackingCategory({
        ...timeTrackingCategory,
        parentId: props.parentId,
      });
      setDefaultValue({
        ...defaultValue,
        parentId: props.parentId,
      });
    }
  }, [props.parentId]);

  const onClose = async () => {
    props.onClose();
  };

  const onSave = async () => {
    try {
      if (timeTrackingCategory.name != '') {
        let res = null;
        if (timeTrackingCategory.id)
          res = await AdminApi.editTimeTrackingCategories(
            context.authToken,
            timeTrackingCategory,
          );
        else
          res = await AdminApi.createTimeTrackingCategories(
            context.authToken,
            timeTrackingCategory,
          );
        if (res.message) {
          snackbarUtils.success(res.message);
          setTimeTrackingCategory(defaultValue);
          onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={props.open} onClose={onClose}>
      <DialogTitle>
        {props.timeTrackingCategory
          ? context.i18n.timeTrackingCategoryEdit
          : context.i18n.timeTrackingCategoryCreate}
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={timeTrackingCategory.name == ''}
              label={context.i18n.timeTrackingCategoryName}
              size="medium"
              helperText={
                timeTrackingCategory.name == ''
                  ? context.i18n.timeTrackingCategoryNameError
                  : ''
              }
              fullWidth
              value={timeTrackingCategory.name}
              onChange={(e) =>
                setTimeTrackingCategory({
                  ...timeTrackingCategory,
                  name: e.currentTarget.value,
                })
              }
            />
          </Grid>
          {timeTrackingCategory.parentId && (
            <>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={timeTrackingCategory.isClientOrFamilyNeeded}
                        onChange={(e) =>
                          setTimeTrackingCategory({
                            ...timeTrackingCategory,
                            isClientOrFamilyNeeded: e.target.checked,
                          })
                        }
                      />
                    }
                    label={
                      context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={context.i18n.timeTrackingCategoryMethod}
                      helperText={
                        timeTrackingCategory.method
                          ? context.i18n[
                              'timeTrackingCategoryMethodDescription_' +
                                timeTrackingCategory.method
                            ]
                          : ''
                      }
                    />
                  )}
                  getOptionLabel={(option) =>
                    context.i18n['timeTrackingCategoryMethod_' + option]
                  }
                  options={Object.keys(TimeTrackingCategoryMethod).map(
                    (e) => e,
                  )}
                  value={timeTrackingCategory.method}
                  onChange={(e, val) => {
                    if (val)
                      setTimeTrackingCategory({
                        ...timeTrackingCategory,
                        method: val as TimeTrackingCategoryMethod,
                      });
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimeTrackingCategoryDialog;
