import { Box, Fab, Grid, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { FormElement } from '../../../client';
import {
  ClientDataType,
  FormFieldType,
  ReferencePersonRole,
  RelationshipType,
  Sex,
} from '@casecare/types';
import { FormFieldStyle } from '../../../../types/form-element-props';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import AvatarDeadIcon from '../../../avatar/avatar-dead-icon/avatar-dead-icon';
import { sortEnumWithLanguage } from '../../../../utils/core/misc-utils';

interface EcoMapClientDataProps {
  // family: any;
  client: any;
}

const EcoMapClientData: FC<EcoMapClientDataProps> = (props) => {
  const context = useContext(AppContext);
  const [client, setClient] = useState<any>(props.client);
  const groupId = 'Eco-Map-Client-Data-' + client.id;
  const { clientId } = useParams();

  const [isFamily, setIsFamily] = useState(true);
  const [isReference, setIsReference] = useState(true);
  // const [family, setFamily] = useState<any[]>([]);

  useEffect(() => {
    setClient(props.client);
    setIsFamily(props.client.role ? false : true);
    setIsReference(props.client.role ? true : false);
  }, [props.client]);

  useEffect(() => {
    context.getAndRemoveGroupData(groupId);
  }, []);

  const onEditAvatar = () => {
    window.open('/avatar/editor/' + clientId + '/' + client.id, '_blank');
  };

  return (
    <>
      <Box sx={{ m: -2 }}>
        <Grid container>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT}
              value={client[ClientDataType.FIRST_NAME]}
              title={context.i18n.firstName}
              id={ClientDataType.FIRST_NAME}
              groupId={groupId}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT}
              value={client[ClientDataType.LAST_NAME]}
              title={context.i18n.lastName}
              id={ClientDataType.LAST_NAME}
              groupId={groupId}
              style={FormFieldStyle.NORMAL}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.DROPDOWN}
              value={
                client[ClientDataType.SEX] !== null
                  ? client[ClientDataType.SEX]
                  : ''
              }
              title={context.i18n.sex}
              id={ClientDataType.SEX}
              fieldOptions={Object.entries(Sex).map((sex) => {
                return { key: sex[0], label: context.i18n[sex[0]] };
              })}
              style={FormFieldStyle.NORMAL}
              groupId={groupId}
            />
          </Grid>
          {client.id !== clientId && (
            <Grid item xs={12} lg={6}>
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.RADIO}
                value={
                  typeof client.isRealPerson !== 'undefined'
                    ? client.isRealPerson
                      ? 1
                      : 2
                    : 0
                }
                title={''}
                id={'isRealPerson'}
                fieldOptions={[
                  {
                    key: 1,
                    label: context.i18n.realPerson,
                  },
                  {
                    key: 2,
                    label: context.i18n.fictionalPerson,
                  },
                ]}
                groupId={groupId}
              />
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.CHECKBOX}
                value={client.isDead && [0]}
                title={''}
                id={'isDead'}
                fieldOptions={[
                  {
                    key: 0,
                    label: context.i18n.alreadyDead,
                  },
                ]}
                groupId={groupId}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            lg={client.avatar && client.avatar !== null ? 9 : 12}
          >
            {client.id !== clientId && (
              <>
                <FormElement
                  dialog={true}
                  hideExport
                  type={FormFieldType.DROPDOWN}
                  value={
                    client.relationship !== null ? client.relationship : ''
                  }
                  title={context.i18n.relationShip}
                  id={'relationship'}
                  fieldOptions={sortEnumWithLanguage(RelationshipType)}
                  onChange={(value) => {
                    if (value) setIsFamily(true);
                    else setIsFamily(false);
                  }}
                  style={FormFieldStyle.NORMAL}
                  groupId={groupId}
                  disabled={isReference}
                />
                <FormElement
                  dialog={true}
                  hideExport
                  type={FormFieldType.TEXT}
                  value={client.role !== null ? client.role : ''}
                  title={context.i18n.role}
                  id={'role'}
                  style={FormFieldStyle.NORMAL}
                  disabled={isFamily}
                  groupId={groupId}
                  onChange={(value) => {
                    if (value) setIsReference(true);
                    else setIsReference(false);
                  }}
                />
              </>
            )}
          </Grid>
          {client.avatar && client.avatar !== null && (
            <Grid item xs={12} lg={3}>
              <Box sx={{ m: 2, mt: 1 }}>
                <Typography>Avatar</Typography>
                <Box
                  sx={{
                    p: 2,
                    borderColor: 'primary.main',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  <Fab
                    onClick={onEditAvatar}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      margin: 0,
                      transform: 'translate(-50%, -50%)',
                    }}
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                  </Fab>
                  <AvatarDeadIcon
                    sx={{
                      '& .MuiBadge-badge': {
                        transform:
                          'scale(' +
                          (client.isDead ? 1 : 0) +
                          ') translate(50%, -25%)',
                        width: '50px',
                        height: '50px',
                      },
                    }}
                  >
                    <img
                      style={{
                        width: 'auto',
                        maxHeight: '150px',
                        filter: client.isDead ? 'grayscale(1)' : 'none',
                      }}
                      src={client.avatar.pngPath}
                      loading="lazy"
                    />
                  </AvatarDeadIcon>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormElement
              dialog={true}
              hideExport
              type={FormFieldType.TEXT_LARGE}
              value={client[ClientDataType.NOTES]}
              title={context.i18n.additionalNotes}
              id={ClientDataType.NOTES}
              groupId={groupId}
              style={FormFieldStyle.OUTLINED}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EcoMapClientData;
