import { FC, useContext, useEffect, useState } from 'react';
import {
  GridColDef,
  DataGridPro,
  GridFooter,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { Family, FamilyDataGridElement, FamilyTabType } from '../../../types';
import { parseDateTimeString, theme } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import TimeTrackingListFooter from './time-tracking-list-footer/time-tracking-list-footer';
import TimeTrackingListToolbar from './time-tracking-list-toolbar/time-tracking-list-toolbar';
import { useQuery } from 'react-query';
import { TimeTrackingApi } from '../../../api';
import { DateRange } from '@mui/x-date-pickers-pro';
import { parseDurationNumber } from '../../../utils/date/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsToDot,
  faFilePen,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TimeTrackingEntry } from '../../../types/time-tracking-entry';
import TimeTrackingEntryDialog from './time-tracking-entry-dialog/time-tracking-entry-dialog';
import DeleteTimeTrackingEntryDialog from './time-tracking-entry-dialog/delete-time-tracking-entry-dialog';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import { TimeTrackingStatus } from '../../../types/time-tracking-status';
import StatusTimeTrackingEntriesDialog from './time-tracking-entry-dialog/status-time-tracking-entries-dialog';
import { TimeTrackingCategoryMethod } from '../../../types/time-tracking-category-method';
import { EditProtocolDialog } from '../../dialogs';

const TimeTrackingList: FC<{
  clientId?: string;
  familyId?: string;
  timeTracking?: string;
}> = (props) => {
  // const context = useContext(AppContext);
  // const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);

  const context = useContext(AppContext);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'date', sort: 'desc' },
  ]);
  const [dateFilter, setDateFilter] = useState<DateRange<Moment>>([
    moment().startOf('week'),
    moment().endOf('week'),
  ]);

  const [statusFilter, setStatusFilter] = useState<TimeTrackingStatus[]>([]);
  const [userFilter, setUserFilter] = useState<string>();

  const [rowCount, setRowCount] = useState(0);
  const [overallDuration, setOverallDuration] = useState(0);

  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);
  const [editProtocolDialogOpen, setEditProtocolDialogOpen] =
    useState<boolean>(false);
  const [selectedTimeTrackingEntry, setSelectedTimeTrackingEntry] =
    useState<TimeTrackingEntry>();

  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [currentTimeTrackingEntry, setCurrentTimeTrackingEntry] =
    useState<TimeTrackingEntry>();

  const [selectedTimeTrackingEntries, setSelectedTimeTrackingEntries] =
    useState<TimeTrackingEntry[]>([]);

  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'timeTrackingList',
      paginationModel,
      sortModel,
      dateFilter,
      statusFilter,
      userFilter,
      props.clientId,
      props.familyId,
    ],
    queryFn: () => {
      const data: any = Object.assign({
        pageNum: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sortModel,
        start: moment(dateFilter[0]).utc(),
        end: moment(dateFilter[1]).utc(),
        status: statusFilter,
        userId: userFilter,
        clientId: props.clientId,
        familyId: props.familyId,
      });
      return TimeTrackingApi.getEntryList(context.authToken, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(data.data.collection);
        setRowCount(data.data.rowCount);
        setOverallDuration(data.data.overallDuration);
        setCurrentTimeTrackingEntry(data.data.startedEntry);
        setTimeTrackingCategories(data.data.categories);
        setClientFamilyList(data.data.clientFamilyList);
        setUserList(data.data.userList);
      }
    }
  }, [data, error, isLoading]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: context.i18n.timeTrackingDate,
      flex: 1,
      hideable: false,
      valueGetter: (value, row) => {
        const date = moment.utc(row?.start);
        if (date.isValid()) return date.format('DD.MM.YYYY');
      },
    },
    {
      field: 'startEnd',
      headerName: context.i18n.timeTrackingStartEnd,
      flex: 1,
      sortable: false,
      valueGetter: (value, row) => {
        const start = moment(row.start);
        const end = moment(row.end);
        return (
          (start.isValid() ? start.format('HH:mm:ss') : '') +
          ' - ' +
          (end.isValid() ? end.format('HH:mm:ss') : '')
        );
      },
    },
    {
      field: 'duration',
      headerName: context.i18n.timeTrackingDuration,
      flex: 1,
      sortable: false,
      valueFormatter: (value) => {
        return value == 0 ? '' : parseDurationNumber(value, true);
      },
    },
    {
      field: 'category',
      headerName: context.i18n.timeTrackingClientCategory,
      flex: 1,
      valueFormatter: (value: any) => {
        return value.name;
      },
    },
    {
      field: 'client_family',
      headerName: context.i18n.timeTrackingClientFamily,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.family) return row.family.name;
        else if (row.client) return row.client.name;
        return '';
      },
    },
    {
      field: 'status',
      headerName: context.i18n.timeTrackingStatus,
      flex: 1,
      valueFormatter: (value: any) => {
        return context.i18n['timeTrackingStatus_' + value];
      },
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Box>
          <Tooltip title={context.i18n.timeTrackingCategoryCreate}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => setCreateDialogOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.timeTrackingEntriesStatus}>
            <IconButton
              disabled={selectedTimeTrackingEntries.length < 1}
              disableRipple
              color="primary"
              onClick={() => setStatusDialogOpen(true)}
            >
              <FontAwesomeIcon icon={faArrowsToDot} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box>
            {params.row.editPermission && (
              <>
                <Tooltip title={context.i18n.timeTrackingCategoryEdit}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() => {
                      setEditDialogOpen(true);
                      setSelectedTimeTrackingEntry(params.row);
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </IconButton>
                </Tooltip>
                {params.row.category.method ==
                  TimeTrackingCategoryMethod.PROTOCOL && (
                  <Tooltip
                    title={context.i18n.timeTrackingCategoryProtocolEdit}
                  >
                    <IconButton
                      disableRipple
                      color="primary"
                      onClick={() => {
                        setSelectedTimeTrackingEntry(params.row);
                        setEditProtocolDialogOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilePen} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {params.row.deletePermission && (
              <Tooltip title={context.i18n.timeTrackingCategoryDelete}>
                <IconButton
                  disableRipple
                  sx={{ height: 39 }}
                  color="error"
                  onClick={() => {
                    setSelectedTimeTrackingEntry(params.row);
                    setDeleteDialogOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          mx: 1,
          '& .MuiDataGrid-row': {
            ['&.status-' + TimeTrackingStatus.REJECTED]: {
              backgroundColor: theme.palette.error.light,
              '--rowBorderColor': theme.palette.error.dark,
            },
            ['&.status-' + TimeTrackingStatus.SUBMITTED]: {
              opacity: 0.5,
              backgroundColor: theme.palette.grey[400],
              '--rowBorderColor': theme.palette.grey[800],
            },
            ['&.status-' + TimeTrackingStatus.COMPLETED]: {
              opacity: 0.5,
            },
          },
        }}
        density="compact"
        columns={tableColumns}
        disableColumnSelector={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooterSelectedRowCount={true}
        columnBufferPx={4}
        disableDensitySelector={true}
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pagination
        getRowClassName={(params) => {
          return 'status-' + params.row.status;
        }}
        pageSizeOptions={[
          25,
          50,
          100,
          { value: -1, label: context.i18n.timeTrackingListAll },
        ]}
        slots={{
          footer: TimeTrackingListFooter,
          toolbar: TimeTrackingListToolbar,
        }}
        slotProps={{
          footer: { duration: overallDuration },
          toolbar: {
            // showDayTimer: true,
            showSubTimer: true,
            filterDateRange: setDateFilter,
            filterStatus: setStatusFilter,
            filterUser: setUserFilter,
            clientFamilyList: clientFamilyList,
            currentTimeTrackingEntry: currentTimeTrackingEntry,
            timeTrackingCategories: timeTrackingCategories,
            userList: userList,
            clientId: props.clientId,
            familyId: props.familyId,
            onTimerStart: (timeTrackingEntry: TimeTrackingEntry) => {
              console.log(timeTrackingEntry);
              refetch();
            },
            onTimerStop: (timeTrackingEntry: TimeTrackingEntry) => {
              switch (timeTrackingEntry.category?.method) {
                case TimeTrackingCategoryMethod.PROTOCOL:
                  setSelectedTimeTrackingEntry(timeTrackingEntry);
                  setEditProtocolDialogOpen(true);
                  break;
              }

              refetch();
            },
          },
        }}
        // getDetailPanelContent={({ row }) => {
        //   if (row.data) return <TimeTrackingListDetail timeTracking={row.data} />;
        //   return null;
        // }}
        // getDetailPanelHeight={({ row }) => 'auto'}
        checkboxSelection
        isRowSelectable={(params) =>
          params.row.editPermission &&
          params.row.status != TimeTrackingStatus.COMPLETED
        }
        rows={rows}
        getRowId={(row) => row.id}
        rowCount={rowCount}
        loading={isLoading}
        sortingMode="server"
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']}
        onRowSelectionModelChange={(ids) =>
          setSelectedTimeTrackingEntries(
            ids.map((id) => rows.find((row) => row.id === id)),
          )
        }
      />
      <TimeTrackingEntryDialog
        open={createDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        userId={userFilter}
        onClose={() => {
          setCreateDialogOpen(false);
          refetch();
        }}
      />
      <TimeTrackingEntryDialog
        open={editDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        timeTrackingEntry={selectedTimeTrackingEntry}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
      />
      <DeleteTimeTrackingEntryDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        timeTrackingEntry={selectedTimeTrackingEntry}
      />
      <StatusTimeTrackingEntriesDialog
        open={statusDialogOpen}
        onClose={() => {
          setStatusDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        timeTrackingEntries={selectedTimeTrackingEntries}
      />
      <EditProtocolDialog
        clientId={selectedTimeTrackingEntry?.clientId}
        familyId={selectedTimeTrackingEntry?.familyId}
        open={editProtocolDialogOpen}
        protocol={selectedTimeTrackingEntry?.protocol}
        onClose={() => {
          setEditProtocolDialogOpen(false);
          refetch();
        }}
        showReleaseButton
        startDate={moment(selectedTimeTrackingEntry?.start).utc()}
        endDate={moment(selectedTimeTrackingEntry?.end).utc()}
        timeTrackingId={selectedTimeTrackingEntry?.id}
        // submitOnSaveEnabled={!billing}
        // onRelease={() => {
        //   if (billing && protocol) {
        //     onReleaseProtocol(protocol.releaseProtocolId);
        //   }
        // }}
        // adminView={billing || context.user?.role !== 'USER'}
        // released={released}
      />
    </>
  );
};

export default TimeTrackingList;
