import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import EcoMapApi from '../../../api/eco-map/eco-map';
import { AdminApi } from '../../../api';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';

interface DeleteTimeTrackingCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingCategory: TimeTrackingCategory | undefined;
}

const DeleteTimeTrackingCategoryDialog: FC<
  DeleteTimeTrackingCategoryDialogProps
> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onDelete = async () => {
    try {
      if (props.timeTrackingCategory && props.timeTrackingCategory.id) {
        const res = await AdminApi.deleteTimeTrackingCategory(
          context.authToken,
          props.timeTrackingCategory.id,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          props.onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.timeTrackingCategoryDelete}</DialogTitle>
      <DialogContent>
        <Typography>{context.i18n.areYouSure}</Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.delete}>
          <IconButton onClick={onDelete} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTimeTrackingCategoryDialog;
