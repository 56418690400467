import { FrontendFormImportField } from '@casecare/types';
import { InviteUser, CategoryData } from '../../types';
import { EditUser } from '../../types/user-dto';
import { axiosInstance } from '../../utils';

export default class AdminApi {
  static inviteUser(token: string | undefined, body: InviteUser): Promise<any> {
    return axiosInstance
      .post('/admin/user/invite', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editUser(token: string | undefined, body: EditUser): Promise<any> {
    return axiosInstance
      .post('/admin/user/edit', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editUserGroup(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('/admin/user/groups/edit', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUsers(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/user', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUserGroups(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/user/groups', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUserGroup(
    token: string | undefined,
    id: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get('/admin/user/group/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createCategory(
    token: string | undefined,
    categoryName: string,
    isDefault: boolean,
  ): Promise<any> {
    return axiosInstance
      .post(
        '/admin/form/categories',
        {
          categories: [
            {
              label: categoryName,
              isDefault,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static createSubCategory(
    token: string | undefined,
    categoryName: string,
    parentId: string | undefined,
    seq?: number,
  ): Promise<any> {
    return axiosInstance
      .post(
        '/admin/form/categories',
        {
          categories: [
            {
              parentCategoryId: parentId,
              label: categoryName,
              seq: seq,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getCategories(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('admin/form/categories', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getCategoryData(
    categoryId: string | undefined,
    token: string | undefined,
  ) {
    return axiosInstance
      .get('admin/form/categories/' + categoryId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteFormField(
    categoryId: string | undefined,
    id: string | undefined,
    token: string | undefined,
  ) {
    return axiosInstance
      .post(
        '/admin/form/categories/' + categoryId,
        {
          fields: [
            {
              id: id,
              delete: true,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateCategoryFormData(
    categoryId: string | undefined,
    elements: FrontendFormImportField[],
    token: string | undefined,
  ) {
    return axiosInstance
      .post(
        '/admin/form/categories/' + categoryId,
        {
          fields: elements,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static updateCategories(
    categories: CategoryData[] | undefined,
    token: string | undefined,
  ) {
    return axiosInstance
      .post(
        '/admin/form/categories',
        {
          categories: categories,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static deleteCategory(
    categoryId: string | undefined,
    token: string | undefined,
  ) {
    return axiosInstance
      .post(
        '/admin/form/categories',
        {
          categories: [
            {
              id: categoryId,
              delete: true,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getImportCategories(token: string | undefined) {
    return axiosInstance
      .get('/admin/form/import/categories', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpForms(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/help-form', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpFormOrderer(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/help-form/orderer', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createHelpForm(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('/admin/help-form', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createHelpFormOrderer(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('/admin/help-form/orderer', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editHelpForm(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .put('/admin/help-form/', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editHelpFormOrderer(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('/admin/help-form/orderer/', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteHelpForm(token: string | undefined, id: string): Promise<any> {
    return axiosInstance
      .delete('/admin/help-form/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteHelpFormOrderer(
    token: string | undefined,
    id: string,
  ): Promise<any> {
    return axiosInstance
      .delete('/admin/help-form/orderer/' + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static listOrgMessages(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/admin/organization-message', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static createOrgMessage(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('/admin/organization-message', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editOrgMessage(
    token: string | undefined,
    body: any,
    messageId: string,
  ): Promise<any> {
    return axiosInstance
      .put('/admin/organization-message/' + messageId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteOrgMessage(
    token: string | undefined,
    messageId: string,
  ): Promise<any> {
    return axiosInstance
      .delete('/admin/organization-message/' + messageId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getTimeTrackingCategories(
    token: string | undefined,
    categoryId?: string | undefined,
  ): Promise<any> {
    return axiosInstance
      .get(
        'admin/time-tracking/categories' + (categoryId ? '/' + categoryId : ''),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static createTimeTrackingCategories(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('admin/time-tracking/category', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editTimeTrackingCategories(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('admin/time-tracking/category', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static deleteTimeTrackingCategory(
    token: string | undefined,
    categoryId: string,
  ): Promise<any> {
    return axiosInstance
      .delete('admin/time-tracking/category/' + categoryId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
