import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import moment from 'moment';
import { TimeTrackingStatus } from '../../../../types/time-tracking-status';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TimeTrackingCategory } from '../../../../types/time-tracking-category';
import { TimeTrackingApi } from '../../../../api';
import { useQuery } from 'react-query';

interface StatusTimeTrackingEntriesDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingEntries: TimeTrackingEntry[];
}

const StatusTimeTrackingEntriesDialog: FC<
  StatusTimeTrackingEntriesDialogProps
> = (props) => {
  const context = useContext(AppContext);
  const [timeTrackingEntries, setTimeTrackingEntries] = useState<
    TimeTrackingEntry[]
  >([]);
  const [timeTrackingStatus, setTimeTrackingStatus] =
    useState<TimeTrackingStatus>();

  useEffect(() => {
    if (props.timeTrackingEntries)
      setTimeTrackingEntries(props.timeTrackingEntries);
  }, [props.timeTrackingEntries]);

  const onClose = async () => {
    props.onClose();
  };

  const onSave = async () => {
    try {
      const ids = timeTrackingEntries.map((val) => val.id);
      if (ids && timeTrackingStatus) {
        const sendData = {
          ids,
          status: timeTrackingStatus,
        };
        const res = await TimeTrackingApi.entryMultiStatusChange(
          context.authToken,
          sendData,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog fullWidth open={props.open} onClose={onClose}>
      <DialogTitle>{context.i18n.timeTrackingEntriesStatus}</DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingStatus}
                />
              )}
              getOptionLabel={(option) =>
                context.i18n['timeTrackingStatus_' + option]
              }
              options={Object.keys(TimeTrackingStatus).map((e) => e)}
              onChange={(e, val) => {
                if (val) setTimeTrackingStatus(val as TimeTrackingStatus);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default StatusTimeTrackingEntriesDialog;
