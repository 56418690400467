import { FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../utils';
import { MenuWrapper } from '../../components/core';
import { Typography } from '@mui/material';
import moment from 'moment';
import { TimeTrackingList } from '../../components/time-tracking';

const TimeTracking: FC = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    setDocumentTitle(context.i18n.siteTitleTimetracking);
    return () => loadTitle();
  }, []);

  return (
    <MenuWrapper
      selected="timeTracking"
      title={context.i18n.siteTitleTimetracking}
    >
      <TimeTrackingList />
    </MenuWrapper>
  );
};

export default TimeTracking;
