import {
  faClock,
  faEye,
  faPenToSquare,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import {
  loadTitle,
  parseDateTimeString,
  setDocumentTitle,
} from '../../../utils';
import { MenuWrapper } from '../../../components/core';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import CreateOrgMessageDialog from '../../../components/admin/organization-message/create-message-dialog';
import EditOrgMessageDialog from '../../../components/admin/organization-message/edit-message-dialog';

interface DataGridElement {
  id: string;
  username: string;
  isAdmin: boolean;
}

const OrganizationMessages = () => {
  const context = useContext(AppContext);
  const [rows, setRows] = useState<DataGridElement[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [createOrgMessageDialogOpen, setCreateOrgMessageDialogOpen] =
    useState(false);
  const [viewsAnchorEl, setViewsAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [currentViewsData, setCurrentViewsData] = useState<
    { user: string; isViewed: boolean; viewDate?: string }[] | null
  >();
  const [selectedMessage, setSelectedMessage] = useState<any>(undefined);
  const [viewCount, setViewCount] = useState(0);

  const handleViewsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    viewsData: { user: string; isViewed: boolean; viewDate?: string }[],
  ) => {
    setViewsAnchorEl(event.currentTarget);
    setCurrentViewsData(viewsData);
    setViewCount(viewsData.filter((v) => v.isViewed).length);
  };

  const handleViewsClose = () => {
    setViewsAnchorEl(null);
    setCurrentViewsData(null);
    setViewCount(0);
  };

  useEffect(() => {
    setDocumentTitle(context.i18n.orgMessages);
    return () => loadTitle();
  }, []);

  const { isLoading, error, data } = useQuery({
    queryKey: ['listOrgMessages'],
    queryFn: () => AdminApi.listOrgMessages(context.authToken),
    enabled: context.authToken !== undefined && context.isAdmin,
    onSuccess: (data) => setRows(data.data),
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data.users) {
      setRows(data.data.users);
      setTotalRowCount(data.data.users.length);
    }
  }, [data, error]);

  const columns: GridColDef[] = [
    {
      field: 'subject',
      headerName: context.i18n.subject,
      editable: false,
      flex: 1,
    },
    {
      field: 'message',
      headerName: context.i18n.message,
      editable: false,
      flex: 1,
    },
    {
      field: 'creator',
      headerName: context.i18n.creator,
      editable: false,
      flex: 1,
    },
    {
      field: 'creationDate',
      headerName: context.i18n.creationDate,
      editable: false,
      flex: 1,
      valueFormatter: (value) => parseDateTimeString(value),
    },
    {
      field: 'expirationDate',
      headerName: context.i18n.expirationDate,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          {parseDateTimeString(params.value) || ''}
          {params.row.isExpired && (
            <Tooltip title={context.i18n.expired}>
              <IconButton disableRipple color="warning" size="small">
                <FontAwesomeIcon icon={faClock} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.inviteUser}>
          <IconButton
            disableRipple
            color="primary"
            id="create-message-button"
            data-id="create-message-button"
            onClick={() => setCreateOrgMessageDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          <IconButton
            disableRipple
            color="primary"
            onClick={(event) => handleViewsClick(event, params.row.views)}
          >
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
          <Tooltip title="">
            <IconButton
              disableRipple
              color="primary"
              sx={{ height: 39 }}
              onClick={() => {
                setSelectedMessage(params.row);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <MenuWrapper
      title={context.i18n.userManagement}
      selected="admin-org-messages"
      addClient
      search
      admin
    >
      <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
        <DataGridPro
          sx={{
            m: 5,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getRowId={(row) => row.id}
          rowCount={totalRowCount}
          autoHeight
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <CreateOrgMessageDialog
        open={createOrgMessageDialogOpen}
        onClose={() => setCreateOrgMessageDialogOpen(false)}
      />
      <EditOrgMessageDialog
        open={selectedMessage}
        onClose={() => setSelectedMessage(undefined)}
        orgMessage={selectedMessage}
      />
      <Popover
        open={Boolean(viewsAnchorEl)}
        anchorEl={viewsAnchorEl}
        onClose={handleViewsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: 2 }}>
          {currentViewsData && (
            <>
              <Typography sx={{ mb: 1.5 }}>
                <b>{context.i18n.viewed}:</b> {viewCount}/
                {currentViewsData.length}
              </Typography>
              <Typography>
                <b>{context.i18n.viewed}:</b>
              </Typography>
              <Typography>
                {currentViewsData
                  .filter((v) => v.isViewed)
                  .map((v) => (
                    <Box key={v.user}>
                      {v.user} ({parseDateTimeString(v.viewDate)})
                    </Box>
                  ))}
                {viewCount === 0 && '-'}
              </Typography>

              {viewCount < currentViewsData.length && (
                <>
                  <Typography>
                    <b>{context.i18n.notViewed}:</b>
                  </Typography>
                  <Typography>
                    {currentViewsData
                      .filter((v) => !v.isViewed)
                      .map((v) => (
                        <Box key={v.user}>{v.user}</Box>
                      ))}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Popover>
    </MenuWrapper>
  );
};

export default OrganizationMessages;
