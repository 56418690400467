import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  GridColDef,
  DataGridPro,
  GridFooterContainer,
  GridFooter,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  ButtonProps,
  ButtonTypeMap,
  ExtendButtonBase,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import {
  Family,
  FamilyDataGridElement,
  FamilyTabType,
} from '../../../../types';
import { parseDateTimeString } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import CountUpTimer from '../../../core/count-up-timer/count-up-timer';
import { ClientApi, TimeTrackingApi } from '../../../../api';
import { useQuery } from 'react-query';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { TimeTrackingCategory } from '../../../../types/time-tracking-category';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import { TimeTrackingStatus } from '../../../../types/time-tracking-status';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    showDayTimer?: boolean;
    showSubTimer?: boolean;
    currentTimeTrackingEntry: TimeTrackingEntry;
    timeTrackingCategories: TimeTrackingCategory[];
    clientFamilyList: any[];
    userList?: any[];
    clientId?: string;
    familyId?: string;
    filterDateRange?: (dateRange: DateRange<Moment>) => void;
    filterStatus?: (status: TimeTrackingStatus[]) => void;
    filterUser?: (userId?: string) => void;
    onTimerStart?: (timeTrackingEntry: TimeTrackingEntry) => void;
    onTimerStop?: (timeTrackingEntry: TimeTrackingEntry) => void;
  }
}

export const TimerWithStartStopButton = (props: {
  timer: undefined | Moment;
  onStart: (timer: Moment) => void;
  onStop: (timer: Moment) => void;
  startDisabled?: boolean;
}) => {
  const [timer, setTimer] = useState<undefined | Moment>(props.timer);
  useEffect(() => {
    setTimer(props.timer);
  }, [props.timer]);

  const context = useContext(AppContext);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      sx={{ width: '100%' }}
    >
      <Typography sx={{ mr: 1, fontWeight: 'bold' }}>
        <CountUpTimer startTime={timer?.unix() ?? 0} />
      </Typography>
      {timer ? (
        <Button
          variant={'outlined'}
          color={'error'}
          fullWidth
          onClick={() => {
            setTimer(undefined);
            props.onStop(moment());
          }}
        >
          <Typography>{context.i18n.timeTrackingButtonStop}</Typography>
        </Button>
      ) : (
        <Button
          variant={'contained'}
          color={'success'}
          disabled={props.startDisabled}
          fullWidth
          onClick={() => {
            const curr = moment();
            setTimer(curr);
            props.onStart(curr);
          }}
        >
          <Typography>{context.i18n.timeTrackingButtonStart}</Typography>
        </Button>
      )}
    </Box>
  );
};

const TimeTrackingListToolbar = (
  props: NonNullable<GridSlotsComponentsProps['toolbar']>,
) => {
  const context = useContext(AppContext);

  const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
    {
      label: context.i18n.timeTrackingThisWeek,
      getValue: () => {
        const today = moment();
        return [today.clone().startOf('week'), today.clone().endOf('week')];
      },
    },
    {
      label: context.i18n.timeTrackingLastWeek,
      getValue: () => {
        const today = moment();
        const prevWeek = today.subtract(7, 'day');
        return [
          prevWeek.clone().startOf('week'),
          prevWeek.clone().endOf('week'),
        ];
      },
    },
    {
      label: context.i18n.timeTrackingThisMonth,
      getValue: () => {
        const today = moment();
        return [today.clone().startOf('month'), today.clone().endOf('month')];
      },
    },
    {
      label: context.i18n.timeTrackingLastMonth,
      getValue: () => {
        const today = moment();
        const endOfLastMonth = today.subtract(1, 'month');

        return [
          endOfLastMonth.clone().startOf('month'),
          endOfLastMonth.clone().endOf('month'),
        ];
      },
    },
  ];

  const [timeTrackingCategorySelected, setTimeTrackingCategorySelected] =
    useState<TimeTrackingCategory | null>(null);

  const [clientFamilySelected, setClientFamilySelected] = useState<any | null>(
    null,
  );

  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [currentTimeTrackingEntry, setCurrentTimeTrackingEntry] =
    useState<TimeTrackingEntry | null>(null);
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);

  const [disableStart, setDisableStart] = useState(true);

  useEffect(() => {
    if (timeTrackingCategorySelected == undefined) setDisableStart(true);
    else if (
      timeTrackingCategorySelected.isClientOrFamilyNeeded &&
      clientFamilySelected == null
    )
      setDisableStart(true);
    else setDisableStart(false);
  }, [timeTrackingCategorySelected, clientFamilySelected]);

  useEffect(() => {
    if (props.currentTimeTrackingEntry) {
      setCurrentTimeTrackingEntry(props.currentTimeTrackingEntry);
      if (props.currentTimeTrackingEntry.category)
        setTimeTrackingCategorySelected(
          props.currentTimeTrackingEntry.category,
        );
    }
  }, [props.currentTimeTrackingEntry]);

  useEffect(() => {
    if (props.timeTrackingCategories)
      setTimeTrackingCategories(props.timeTrackingCategories);
  }, [props.timeTrackingCategories]);

  useEffect(() => {
    if (props.clientFamilyList) setClientFamilyList(props.clientFamilyList);
  }, [props.clientFamilyList]);

  const resetClientFamilySelect = () => {
    if (props.clientId)
      setClientFamilySelected(
        clientFamilyList.find(
          (ele) => ele.id == props.clientId && !ele.isFamily,
        ),
      );
    if (props.familyId) {
      setClientFamilySelected(
        clientFamilyList.find(
          (ele) => ele.id == props.familyId && ele.isFamily,
        ),
      );
    }
  };

  useEffect(() => {
    resetClientFamilySelect();
  }, [props.clientId, props.familyId, clientFamilyList]);

  return (
    <Box>
      <Grid container sx={{ m: 1 }} spacing={2}>
        {props.showSubTimer && (
          <Grid item sm={2}>
            <TimerWithStartStopButton
              startDisabled={disableStart}
              timer={
                currentTimeTrackingEntry?.startRealTime
                  ? moment(currentTimeTrackingEntry.startRealTime)
                  : undefined
              }
              onStart={async (timer) => {
                if (timeTrackingCategorySelected) {
                  const sendData = Object.assign(
                    {
                      start: timer.utc(),
                      categoryId: timeTrackingCategorySelected.id,
                    },
                    timeTrackingCategorySelected.isClientOrFamilyNeeded &&
                      (clientFamilySelected && clientFamilySelected.isFamily
                        ? { familyId: clientFamilySelected.id }
                        : { clientId: clientFamilySelected.id }),
                  );

                  const res = await TimeTrackingApi.createEntry(
                    context.authToken,
                    sendData,
                  );
                  if (res.data) {
                    setCurrentTimeTrackingEntry(res.data);
                    if (props.onTimerStart) props.onTimerStart(res.data);
                  }
                }
              }}
              onStop={async (timer) => {
                if (currentTimeTrackingEntry && currentTimeTrackingEntry.id) {
                  const sendData = {
                    end: timer.utc(),
                  };
                  const res = await TimeTrackingApi.editEntry(
                    context.authToken,
                    currentTimeTrackingEntry.id,
                    sendData,
                  );
                  if (res) {
                    setCurrentTimeTrackingEntry(null);
                    setTimeTrackingCategorySelected(null);
                    resetClientFamilySelect();
                    if (props.onTimerStop) props.onTimerStop(res.data);
                  }
                }
              }}
            />
            <Autocomplete
              disabled={currentTimeTrackingEntry != undefined}
              sx={{ my: 1 }}
              size="small"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingCategory}
                />
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (value.id) return option.id == value.id;
                else return false;
              }}
              options={timeTrackingCategories}
              groupBy={(option: any) => option.parent.name}
              value={timeTrackingCategorySelected}
              onChange={(e, val) => {
                setTimeTrackingCategorySelected(null);
                if (val) setTimeTrackingCategorySelected(val);
              }}
            />

            {timeTrackingCategorySelected?.isClientOrFamilyNeeded && (
              <Autocomplete
                disabled={currentTimeTrackingEntry != undefined}
                size="small"
                fullWidth
                sx={{ my: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded
                    }
                  />
                )}
                groupBy={(option: any) => {
                  if (option.isFamily) return context.i18n.family;
                  return context.i18n.client;
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  if (value.id) return option.id == value.id;
                  else return false;
                }}
                onChange={(e, val) => {
                  setClientFamilySelected(null);
                  if (val) setClientFamilySelected(val);
                }}
                value={clientFamilySelected}
                options={clientFamilyList.sort((x) => (x.isFamily ? -1 : 1))}
              />
            )}
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ m: 1 }} spacing={2}>
        {props.filterDateRange && (
          <Grid item sm={12} md={2}>
            <DateRangePicker
              sx={{ width: '100%' }}
              label={context.i18n.timeTrackingFilterDate}
              onChange={(value) => {
                if (
                  value &&
                  value[0] != null &&
                  value[1] != null &&
                  props.filterDateRange
                )
                  props.filterDateRange([
                    value[0].startOf('day'),
                    value[1].endOf('day'),
                  ]);
              }}
              defaultValue={[moment().startOf('week'), moment().endOf('week')]}
              format="DD.MM.YYYY"
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              calendars={2}
            />
          </Grid>
        )}
        {props.filterStatus && (
          <Grid item sm={12} md={2}>
            <Autocomplete
              fullWidth
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingStatus}
                />
              )}
              getOptionLabel={(option) =>
                context.i18n['timeTrackingStatus_' + option]
              }
              options={Object.keys(TimeTrackingStatus).map((e) => e)}
              onChange={(e, val) => {
                if (props.filterStatus) {
                  if (val) props.filterStatus(val as TimeTrackingStatus[]);
                  else props.filterStatus([]);
                }
              }}
            />
          </Grid>
        )}
        {props.filterUser && props.userList && (
          <Grid item sm={12} md={2}>
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={context.i18n.timeTrackingUser} />
              )}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              getOptionLabel={(option) => option.name}
              options={props.userList}
              onChange={(e, val) => {
                if (props.filterUser) {
                  if (val) props.filterUser(val.id);
                  else props.filterUser(undefined);
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      <GridToolbar />
    </Box>
  );
};

export default TimeTrackingListToolbar;
