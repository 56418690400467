import {
  FormFieldType,
  ReferencePersonRole,
  RelationshipType,
  Sex,
} from '@casecare/types';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AvatarApi, FamilyApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { AvatarTemplateDialog } from '../../../components/dialogs';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { FormElement } from '../../../components/client';
import { FormFieldStyle } from '../../../types/form-element-props';
import { sortEnumWithLanguage } from '../../../utils/core/misc-utils';

const AvatarDetails: FC = () => {
  const context = useContext(AppContext);
  const { clientId, indexId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const initialSex = Object.entries(Sex).slice(0, 3);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [sex, setSex] = useState('');
  const [sexError, setSexError] = useState(false);
  const [diversSelected, setDiversSelected] = useState(false);
  const [selectSexOpen, setSelectSexOpen] = useState(false);
  const [role, setRole] = useState<string | undefined>(undefined);
  const [relationShip, setRelationShip] = useState<RelationshipType | string>(
    '',
  );
  const [isDead, setIsDead] = useState(false);
  const [isRealPerson, setIsRealPerson] = useState(true);
  const [notes, setNotes] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loadTemplates, setLoadTemplates] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [family, setFamily] = useState<any>();
  const [isReferencePerson, setIsReferencePerson] = useState(false);
  const [isFamily, setIsFamily] = useState(false);

  useEffect(() => {
    setDocumentTitle(context.i18n.avatar);
    return () => loadTitle();
  }, []);

  const avatarDetails = useQuery(
    ['avatarDetails', clientId],
    () => AvatarApi.getAvatarDetails(context.authToken, clientId, indexId),
    {
      enabled: clientId !== undefined && context.authToken !== undefined,
      onSuccess: (res) => {
        const data = res.data;
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setSex(data.sex);
        setRole(data.role);
        setIsReferencePerson(data.isReferencePerson);
        setRelationShip(data.relationShip);
        setIsFamily(!data.isReferencePerson);
        setIsDead(data.isDead);
        setIsRealPerson(data.isRealPerson);
        setNotes(data.notes);
        setFamily(data.family);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  useQuery(
    ['avatarTemplates', indexId, loadTemplates],
    () =>
      AvatarApi.listAvatarTemplates(context.authToken, { clientId, indexId }),
    {
      enabled: context.authToken !== undefined && loadTemplates,
      onSuccess: (res) => {
        if (res.data.hasAvatar) {
          onSubmit();
        } else {
          setAvatars(res.data.avatars);
          setTemplates(res.data.templates);
          setOpenDialog(true);
        }
        setLoadTemplates(false);
      },
      onError: (e) => {
        const err = e as AxiosError;
        const errorData = err.response?.data;
        errorData.detail.forEach((message: string) => {
          snackbarUtils.error(message);
        });
      },
    },
  );

  const onContinue = () => {
    if (firstName === '') {
      setFirstNameError(true);
    }

    if (lastName === '' && !isReferencePerson) {
      setLastNameError(true);
    }

    if (sex === '') {
      setSexError(true);
    }

    if (
      firstName !== '' &&
      (lastName !== '' || isReferencePerson) &&
      sex !== ''
    ) {
      setLoadTemplates(true);
    }
  };

  const onSubmit = async (id?: string, isTemplate?: boolean) => {
    const body = Object.assign(
      {
        sex,
        firstName,
        lastName,
        isDead,
        isRealPerson,
        clientId,
        notes,
        family,
      },
      role && {
        role,
      },
      relationShip && {
        relationShip,
      },
      id &&
        isTemplate && {
          templateId: id,
        },
      id &&
        !isTemplate && {
          avatarId: id,
        },
    );

    try {
      const res = await AvatarApi.createAvatar(
        context.authToken,
        indexId,
        body,
      );

      const errors = res.data.errors;
      if (errors && errors.length > 0) {
        errors.forEach((error: any) => {
          snackbarUtils.error(error.message);
        });
      } else {
        navigate(`/avatar/editor/${indexId}/${res.data.clientId}`);
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return avatarDetails.isLoading ? (
    <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
  ) : (
    <Box m={4}>
      <Typography mb={8} variant="h4">
        {context.i18n.createAvatarFor}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            required
            id="avatar-first-name"
            label={context.i18n.firstName}
            variant="standard"
            value={firstName}
            error={firstNameError}
            onChange={(e) => {
              setFirstNameError(false);
              setFirstName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            required={!isReferencePerson}
            id="avatar-last-name"
            label={context.i18n.lastName}
            variant="standard"
            value={lastName}
            error={lastNameError}
            onChange={(e) => {
              setLastNameError(false);
              setLastName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl error={sexError} required fullWidth>
            <InputLabel sx={{ alignSelf: 'center' }} id="sex-slect-label">
              {context.i18n.sex}
            </InputLabel>
            <Select
              labelId="sex-select-label"
              label={context.i18n.sex}
              value={sex}
              open={selectSexOpen}
              onClick={(e) => {
                if (!e.defaultPrevented) setSelectSexOpen(!selectSexOpen);
              }}
            >
              {!diversSelected
                ? initialSex.map((sex) => (
                    <MenuItem
                      key={sex[1]}
                      value={sex[1]}
                      onClick={(e) => {
                        setSexError(false);
                        setSex(sex[0]);
                        if (sex[1] === Sex.DIVERS) {
                          setDiversSelected(true);
                          e.preventDefault();
                        }
                      }}
                    >
                      {context.i18n[sex[0]]}
                    </MenuItem>
                  ))
                : Object.entries(Sex).map((sex) => (
                    <MenuItem
                      key={sex[1]}
                      value={sex[1]}
                      onClick={() => {
                        setSex(sex[0]);
                      }}
                    >
                      {context.i18n[sex[0]]}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          {indexId !== clientId && (
            <>
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.DROPDOWN}
                value={relationShip}
                title={context.i18n.relationShip}
                disableGlobalOnChange
                id={'relationship'}
                fieldOptions={sortEnumWithLanguage(RelationshipType)}
                onChange={(value) => {
                  if (value) setIsFamily(true);
                  else setIsFamily(false);
                  setRelationShip(value);
                }}
                style={FormFieldStyle.NORMAL}
                disabled={isReferencePerson}
              />
              <FormElement
                dialog={true}
                hideExport
                type={FormFieldType.TEXT}
                value={role}
                title={context.i18n.role}
                id={'role'}
                disableGlobalOnChange
                style={FormFieldStyle.NORMAL}
                disabled={isFamily}
                onChange={(value) => {
                  if (value) setIsReferencePerson(true);
                  else setIsReferencePerson(false);
                  setRole(value);
                }}
              />
            </>
          )}
        </Grid>
        <Grid item xs={5}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox checked={isDead} onClick={() => setIsDead(!isDead)} />
              }
              label={context.i18n.alreadyDead}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRealPerson}
                  onClick={() => setIsRealPerson(true)}
                />
              }
              label={context.i18n.realPerson}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isRealPerson}
                  onClick={() => setIsRealPerson(false)}
                />
              }
              label={context.i18n.fictionalPerson}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            multiline
            fullWidth
            rows={5}
            label={context.i18n.additionalNotes}
          />
        </Grid>
      </Grid>
      <Stack
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="center"
        mt={4}
      >
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '0.5px solid #96c1e9',
            borderRadius: 2,
            bgcolor: '#f5f9fd',
            width: 100,
          }}
          disableRipple
          onClick={onContinue}
        >
          <FontAwesomeIcon color="green" icon={faCheck} />
          <Typography>{context.i18n.continue}</Typography>
        </IconButton>
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '0.5px solid #96c1e9',
            borderRadius: 2,
            bgcolor: '#f5f9fd',
            width: 100,
          }}
          disableRipple
          onClick={() => {
            location.key === 'default' ? window.close() : navigate(-1);
          }}
        >
          <FontAwesomeIcon color="red" icon={faX} />
          <Typography>{context.i18n.cancelBtnTooltip}</Typography>
        </IconButton>
      </Stack>
      <AvatarTemplateDialog
        avatars={avatars}
        templates={templates}
        open={openDialog}
        onSelect={onSubmit}
      />
    </Box>
  );
};

export default AvatarDetails;
